.Markdown {
  font-family: var(--basis-typography-fonts-default);
  font-size: 14px;
  line-height: 1.5;
  background-color: var(--basis-colors-base-blok);
  padding: 0;
  color: var(--basis-colors-text-default);
  overflow-wrap: break-word;
}
.Markdown > *:first-child {
  margin-top: 0 !important;
}
.Markdown > *:last-child {
  margin-bottom: 0 !important;
}
.Markdown a {
  color: var(--basis-colors-link-normal);
  text-decoration: none;
}
.Markdown a.absent {
  color: #cc0000;
}
.Markdown a.anchor {
  display: block;
  padding-left: 30px;
  margin-left: -30px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.Markdown h1,
.Markdown h2,
.Markdown h3,
.Markdown h4,
.Markdown h5,
.Markdown h6 {
  margin: 20px 0 10px;
  padding: 0;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: text;
  position: relative;
  color: inherit;
}
.Markdown h2:first-child,
.Markdown h1:first-child,
.Markdown h1:first-child + h2,
.Markdown h3:first-child,
.Markdown h4:first-child,
.Markdown h5:first-child,
.Markdown h6:first-child {
  margin-top: 0;
  padding-top: 0;
}
.Markdown h1:hover a.anchor,
.Markdown h2:hover a.anchor,
.Markdown h3:hover a.anchor,
.Markdown h4:hover a.anchor,
.Markdown h5:hover a.anchor,
.Markdown h6:hover a.anchor {
  text-decoration: none;
}
.Markdown h1 tt,
.Markdown h1 code {
  font-size: inherit;
}
.Markdown h2 tt,
.Markdown h2 code {
  font-size: inherit;
}
.Markdown h3 tt,
.Markdown h3 code {
  font-size: inherit;
}
.Markdown h4 tt,
.Markdown h4 code {
  font-size: inherit;
}
.Markdown h5 tt,
.Markdown h5 code {
  font-size: inherit;
}
.Markdown h6 tt,
.Markdown h6 code {
  font-size: inherit;
}
.Markdown h1 {
  font-size: 28px;
}
.Markdown h2 {
  font-size: 24px;
  border-bottom: 1px solid var(--basis-colors-borders-normal);
}
.Markdown h3 {
  font-size: 18px;
}
.Markdown h4 {
  font-size: 16px;
}
.Markdown h5 {
  font-size: 14px;
}
.Markdown h6 {
  font-size: 14px;
}
.Markdown p,
.Markdown blockquote,
.Markdown ul,
.Markdown ol,
.Markdown dl,
.Markdown table,
.Markdown pre {
  margin: 1.5em 0;
}
.Markdown hr {
  border: 0 none;
  color: var(--basis-colors-text-default);
  height: 4px;
  padding: 0;
}
.Markdown > h2:first-child {
  margin-top: 0;
  padding-top: 0;
}
.Markdown > h1:first-child {
  margin-top: 0;
  padding-top: 0;
}
.Markdown > h1:first-child + h2 {
  margin-top: 0;
  padding-top: 0;
}
.Markdown > h3:first-child, .Markdown > h4:first-child, .Markdown > h5:first-child, .Markdown > h6:first-child {
  margin-top: 0;
  padding-top: 0;
}
.Markdown a:first-child h1,
.Markdown a:first-child h2,
.Markdown a:first-child h3,
.Markdown a:first-child h4,
.Markdown a:first-child h5,
.Markdown a:first-child h6 {
  margin-top: 0;
  padding-top: 0;
}
.Markdown h1 p,
.Markdown h2 p,
.Markdown h3 p,
.Markdown h4 p,
.Markdown h5 p,
.Markdown h6 p {
  margin-top: 0;
}
.Markdown li p.first {
  display: inline-block;
}
.Markdown ul,
.Markdown ol {
  padding-left: 30px;
}
.Markdown ul :first-child,
.Markdown ol :first-child {
  margin-top: 0;
}
.Markdown ul :last-child,
.Markdown ol :last-child {
  margin-bottom: 0;
}
.Markdown dl {
  padding: 0;
}
.Markdown dl dt {
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  padding: 0;
  margin: 1.5em 0 5px;
}
.Markdown dl dt:first-child {
  padding: 0;
}
.Markdown dl dt > :first-child {
  margin-top: 0;
}
.Markdown dl dt > :last-child {
  margin-bottom: 0;
}
.Markdown dl dd {
  margin: 0 0 15px;
  padding: 0 15px;
}
.Markdown dl dd > :first-child {
  margin-top: 0;
}
.Markdown dl dd > :last-child {
  margin-bottom: 0;
}
.Markdown blockquote {
  border-left: 4px solid var(--basis-colors-borders-normal);
  padding: 0 15px;
  color: var(--basis-colors-text-default);
}
.Markdown blockquote > :first-child {
  margin-top: 0;
}
.Markdown blockquote > :last-child {
  margin-bottom: 0;
}
.Markdown table {
  padding: 0;
}
.Markdown table tr {
  border-top: 1px solid var(--basis-colors-borders-normal);
  background-color: var(--basis-colors-base-blok) !important;
  margin: 0;
  padding: 0;
}
.Markdown table tr:nth-child(2n) {
  background-color: var(--basis-colors-node-zebra) !important;
}
.Markdown table tr th {
  font-weight: bold;
  border: 1px solid var(--basis-colors-borders-normal);
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}
.Markdown table tr td {
  border: 1px solid var(--basis-colors-borders-normal);
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}
.Markdown table tr th :first-child,
.Markdown table tr td :first-child {
  margin-top: 0;
}
.Markdown table tr th :last-child,
.Markdown table tr td :last-child {
  margin-bottom: 0;
}
.Markdown .table-wrap {
  overflow: auto;
}
.Markdown .table-wrap::-webkit-scrollbar {
  appearance: none;
}
.Markdown .table-wrap::-webkit-scrollbar:horizontal {
  height: 11px;
}
.Markdown .table-wrap::-webkit-scrollbar:vertical {
  width: 11px;
}
.Markdown .table-wrap::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid var(--basis-colors-borders-normal);
  background-color: var(--basis-colors-base-blok);
}
.Markdown img {
  max-width: 100%;
}
.Markdown span.frame {
  display: block;
  overflow: hidden;
}
.Markdown span.frame > span {
  border: 1px solid var(--basis-colors-borders-normal);
  display: block;
  float: left;
  overflow: hidden;
  margin: 13px 0 0;
  padding: 7px;
  width: auto;
}
.Markdown span.frame span img {
  display: block;
  float: left;
}
.Markdown span.frame span span {
  clear: both;
  color: var(--basis-colors-text-default);
  display: block;
  padding: 5px 0 0;
}
.Markdown span.align-center {
  display: block;
  overflow: hidden;
  clear: both;
}
.Markdown span.align-center > span {
  display: block;
  overflow: hidden;
  margin: 13px auto 0;
  text-align: center;
}
.Markdown span.align-center span img {
  margin: 0 auto;
  text-align: center;
}
.Markdown span.align-right {
  display: block;
  overflow: hidden;
  clear: both;
}
.Markdown span.align-right > span {
  display: block;
  overflow: hidden;
  margin: 13px 0 0;
  text-align: right;
}
.Markdown span.align-right span img {
  margin: 0;
  text-align: right;
}
.Markdown span.float-left {
  display: block;
  margin-right: 13px;
  overflow: hidden;
  float: left;
}
.Markdown span.float-left span {
  margin: 13px 0 0;
}
.Markdown span.float-right {
  display: block;
  margin-left: 13px;
  overflow: hidden;
  float: right;
}
.Markdown span.float-right > span {
  display: block;
  overflow: hidden;
  margin: 13px auto 0;
  text-align: right;
}
.Markdown code,
.Markdown tt {
  margin: 0 2px;
  padding: 0 5px;
  white-space: nowrap;
  border-radius: 3px;
  background-color: var(--components-badge-colors-secondary-default) !important;
  border: 1px solid var(--basis-colors-borders-normal) !important;
  color: var(--components-badge-colors-secondary-text-default);
}
.Markdown pre code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: transparent;
}
.Markdown .highlight pre {
  background-color: var(--basis-colors-base-blok);
  border: 1px solid var(--basis-colors-borders-normal);
  font-size: 13px;
  line-height: 1.5;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}
.Markdown pre {
  background-color: var(--basis-colors-base-blok);
  border: 1px solid var(--basis-colors-borders-normal);
  font-size: 13px;
  line-height: 1.5;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}
.Markdown pre code,
.Markdown pre tt {
  background-color: transparent;
  border: none;
}
.Markdown .code-keyword {
  color: #910091;
}
.Markdown .code-object {
  color: #000091;
}
.Markdown .code-macro {
  color: #78492a;
}
.Markdown .code-quote,
.Markdown .code-quote .code-keyword,
.Markdown .code-quote .code-object {
  color: #009100;
}
.Markdown .code-quote-red,
.Markdown .code-quote-red .code-keyword,
.Markdown .code-quote-red .code-object {
  color: #910000;
}
.Markdown .code-comment,
.Markdown .code-comment .code-keyword,
.Markdown .code-comment .code-object,
.Markdown .code-comment .code-quote,
.Markdown .code-comment .code-quote-red {
  color: #808080;
}
.Markdown .code-xml .code-keyword {
  font-weight: bold;
}
.Markdown .code-tag {
  color: #000091;
}
.Markdown .code-rainbow {
  background: linear-gradient(90deg, #f00, #ff0 15%, #0f0 30%, #0ff 50%, #00f 65%, #f0f 80%, #f00);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.Markdown div.preformattedContent pre,
.Markdown div.codeContent pre {
  max-height: 30em;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: normal;
}
.Markdown div.preformattedContent pre {
  white-space: pre;
  word-break: normal;
  padding: 9px 12px;
}
.Markdown table div.preformattedContent pre {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.Markdown .linkerror {
  background-color: var(--basis-colors-base-blok);
}
.Markdown .helpheading {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
  font-weight: bold;
  background-color: var(--basis-colors-base-blok);
  padding: 4px 4px 2px;
  margin: 0px;
}
.Markdown .helpcontent {
  padding: 4px 4px 20px 4px;
  background-color: var(--basis-colors-base-blok);
}
.Markdown .gridHover {
  background-color: var(--basis-colors-node-hover);
}
.Markdown .navmenu {
  border: 1px solid var(--basis-colors-borders-normal);
}
.Markdown .optionPadded {
  padding: 2px;
}
.Markdown .optionSelected {
  border: 1px solid var(--basis-colors-borders-normal);
  background-color: var(--basis-colors-node-select);
  padding: 2px;
  margin: -1px;
}
.Markdown .optionSelected a {
  font-weight: bold;
  text-decoration: none;
  color: var(--basis-colors-text-default);
}
.Markdown .menuheading {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
  font-weight: bold;
  background-color: var(--basis-colors-base-blok);
  padding: 4px 4px 2px 4px;
}
.Markdown .menuitems {
  padding: 4px 4px 20px 4px;
  background-color: var(--basis-colors-base-blok);
}
.Markdown .pagetitle {
  font-size: 22px;
  font-weight: bold;
  font-family: var(--basis-typography-fonts-default);
  color: var(--basis-colors-text-default);
}