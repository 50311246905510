.Panel-Assessment .ant-form-item {
  margin-bottom: 0;
}
.Panel-Assessment .ant-form-item label {
  color: var(--basis-colors-text-secondary);
}
.Panel-Assessment .ant-form-item label .anticon {
  color: var(--basis-colors-icon-normal);
}
.Panel-Assessment .ant-form-item .ant-col {
  text-align: left;
}
.Panel-Assessment .ant-form-item:first-child .ant-form-item-control {
  display: flex;
  align-items: center;
}
.Panel-Assessment .ActionsSection {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.CustomIconButton {
  width: 40px;
  height: 40px;
}
.CustomIconButton .Button-Text {
  padding: 0;
}
.CustomIconButton + .CustomIconButton {
  margin-left: 8px;
}