.RuleListDashboard {
  margin: 0 auto;
  position: relative;
  width: 100%;
  box-shadow: 0 5px 5px var(--basis-colors-shadows-modalShadow);
  z-index: 1;
}
.RuleListDashboard_fetching .Overlay {
  background-clip: content-box;
}
.RuleListDashboard .RowWithHelp {
  display: flex;
  align-items: center;
}
.RuleListDashboard .RowWithHelp .IconContainer {
  display: inline-flex;
}
.RuleListDashboard .RowWithHelp .IconContainer .Icon {
  margin-left: 2px;
}
.RuleListDashboard-ScrollRight, .RuleListDashboard-ScrollLeft {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 90%;
  top: 10px;
  width: 96px;
  cursor: pointer;
}
.RuleListDashboard-ScrollRight {
  padding-left: 53px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.0001) 1.29%, var(--basis-colors-base-desk) 21.88%);
  right: 0;
}
.RuleListDashboard-ScrollLeft {
  padding-left: 29px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.0001) 1.29%, var(--basis-colors-base-desk) 21.88%);
  left: 0;
}
.RuleListDashboard_closed {
  max-height: 0;
}
.RuleListDashboard-Container {
  padding: 16px 16px 9px;
  z-index: 0;
  position: relative;
  margin: 0 auto;
  transition: left 0.5s;
  display: flex;
  justify-content: stretch;
  min-width: 840px;
  max-width: 1440px;
}
.RuleListDashboard-Container .RuleFactoid + .RuleFactoid {
  margin-left: 16px;
}
.RuleListDashboard .RuleFactoid {
  flex-wrap: nowrap;
  flex-basis: 216px;
  flex-shrink: 0;
  flex-grow: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.RuleListDashboard .RuleFactoid .LightFactoid {
  font-weight: 400;
}
.RuleListDashboard .RuleFactoid-Icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  border-radius: 24px;
}
.RuleListDashboard .RuleFactoid-Icon__client {
  background-color: var(--basis-colors-node-attention);
}
.RuleListDashboard .RuleFactoid-Icon__count {
  background-color: var(--basis-colors-node-select);
}
.RuleListDashboard .RuleFactoid-Icon__count svg {
  margin-left: 4px;
  color: var(--basis-colors-base-active);
}
.RuleListDashboard .RuleFactoid-Icon__last {
  background-color: var(--basis-colors-node-hover);
}
.RuleListDashboard .RuleFactoid-Body {
  display: flex;
  align-items: center;
}
.RuleListDashboard .RuleFactoid-Count {
  color: #4d4d4d;
  font-size: 28px;
}
.RuleListDashboard .RuleFactoid-Title {
  font-size: 12px;
  color: var(--basis-colors-text-default);
}
.RuleListDashboard .Low {
  background-color: #57ad37;
}
.RuleListDashboard .High {
  background-color: #eb6f1c;
}
.RuleListDashboard .Medium {
  background-color: #ebaf09;
}
.RuleListDashboard .Critical {
  background-color: #e0281b;
}
.RuleListDashboard .MitreCoverageFactoid {
  min-width: 216px;
}
.RuleListDashboard .MitreCoverageFactoid-Data {
  width: 100%;
  font-size: 12px;
  color: var(--basis-colors-text-default);
}
.RuleListDashboard .MitreCoverageFactoid-Bar {
  width: 100%;
  display: flex;
  height: 8px;
}
.RuleListDashboard .MitreCoverageFactoid-Back {
  background-color: #eb6f1c;
  height: 100%;
}
.RuleListDashboard .MitreCoverageFactoid-Front {
  height: 100%;
  background: rgba(0, 0, 0, 0.06);
}