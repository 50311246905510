.Back {
  display: flex;
  align-items: center;
  font-family: var(--basis-typography-fonts-default);
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--basis-colors-text-default);
}
.Back_uppercase {
  text-transform: uppercase;
}
.Back > button {
  padding: 0;
}
.Back > span {
  margin-left: 8px;
}
.Back .IconButton {
  color: var(--basis-colors-icon-normal);
}
.Back .IconButton:hover {
  color: var(--basis-colors-icon-hover) !important;
}