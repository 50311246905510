.LoadingBar-Container {
  width: 100%;
  height: 3px;
}
.LoadingBar-Container .LoadingBar {
  height: inherit;
  background: var(--basis-colors-base-active);
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 9002;
}
.LoadingBar-Container .LoadingBar_done {
  height: 0;
}