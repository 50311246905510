.editable-value-wrap {
  position: relative;
  width: 179px;
  padding: 8px 38px 8px 8px;
  margin-left: -8px;
  margin-bottom: -8px;
}
.editable-value-wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--basis-colors-base-borders-normal);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}
.editable-value-wrap .edit-button {
  width: 28px;
  height: 28px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 4px;
  background-color: var(--components-button-colors-transparent-default);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  opacity: 0;
  transition: opacity 0.3s, background-color 0.3s;
  font-size: 20px;
  cursor: pointer;
}
.editable-value-wrap .edit-button:hover {
  background-color: var(--components-button-colors-transparent-hover);
}
.editable-value-wrap .edit-button .Icon {
  color: var(--basis-colors-icon-normal);
}
.editable-value-wrap:hover:before {
  opacity: 1;
}
.editable-value-wrap:hover .edit-button {
  opacity: 1;
}

.editableInput .button-wrap {
  width: 180px;
  display: flex;
  justify-content: flex-end;
  margin-top: -16px;
}
.editableInput .Button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editableInput .Button.Button_rich {
  margin-right: 8px;
}
.editableInput .icon-button {
  font-size: 20px;
}
.editableInput .priority-select {
  width: 179px;
}
.editableInput .priority-select .icon-option-wrap {
  display: flex;
  align-items: center;
}
.editableInput .priority-select .icon-option-wrap .text-option {
  margin-left: 8px;
}
.editableInput .priority-select .icon-option-wrap .Icon {
  font-size: 20px;
}

.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item .icon-option-wrap {
  display: flex;
  align-items: center;
}
.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item .icon-option-wrap .text-option {
  margin-left: 8px;
}
.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item .icon-option-wrap .Icon {
  font-size: 20px;
}