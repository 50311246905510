.article-card {
  color: var(--basis-colors-text-default);
}
.article-card section + section {
  margin-top: 16px;
}
.article-card .mainInfo {
  display: flex;
}
.article-card .mainInfo > div:first-child {
  flex-grow: 1;
}
.article-card .mainInfo > div:last-child {
  min-width: 200px;
  flex-shrink: 0;
}
.article-card .Button {
  border: 0;
}