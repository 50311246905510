.IncidentListPanel {
  background: var(--basis-colors-base-block);
  box-shadow: 0 0 4px 0 var(--basis-colors-shadows-default);
  border-left: 1px solid var(--basis-colors-borders-normal);
  flex: 0 0 520px;
  overflow: auto;
  z-index: 1;
  transition: flex-basis 0.24s;
}
.IncidentListPanel .Layout-BreadCrumb {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
}
.IncidentListPanel .IncidentListPanelHeader {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
  padding: 12px 16px 12px 8px;
  display: flex;
  align-items: center;
}
.IncidentListPanel .IncidentListPanelHeader .Header {
  flex-grow: 1;
  word-break: break-word;
}
.IncidentListPanel .IncidentListPanelHeader-Controls {
  flex-shrink: 0;
}
.IncidentListPanel_closed {
  flex-basis: 65px;
  overflow: hidden;
}
@media only screen and (min-width: 1900px) {
  .IncidentListPanel_closed {
    transition: ease all 0.5s;
    margin-left: -60px;
  }
}
.IncidentListPanel_closed .IncidentListPanelHeader {
  height: 100%;
  width: 65px;
  padding: 18px 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.IncidentListPanel_closed .IncidentListPanelHeader .Header {
  order: 1;
  writing-mode: vertical-lr;
  width: 40px;
  line-height: 40px;
}
.IncidentListPanel_hide {
  flex-basis: 0;
}
.IncidentListPanel .PanelIcon {
  transition: transform 0.24s;
  height: 40px;
}
.IncidentListPanel .PanelIcon_active {
  transform: rotate(180deg);
}
.IncidentListPanel .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 4px 16px 4px 16px !important;
}
.IncidentListPanel .ant-collapse-header .Header {
  flex-grow: 1;
  word-break: break-word;
}
.IncidentListPanel .ant-collapse-content-box {
  padding: 16px 0 24px;
}
.IncidentListPanel .ant-collapse-item:last-child {
  border: 0;
}
.IncidentListPanel .ant-collapse-item .noData {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--basis-colors-text-secondary);
  padding-left: 16px;
  padding-right: 16px;
}
.IncidentListPanel .ant-collapse-item .Panel-Assessment {
  padding-left: 16px;
  padding-right: 16px;
}
.IncidentListPanel .Panel-RatingSection {
  padding: 0 16px;
}
.IncidentListPanel .Panel-RatingSection .Text {
  color: var(--basis-colors-text-secondary);
}

.priority-select {
  width: 179px;
}

.actionIncWrap {
  display: flex;
  color: var(--basis-colors-base-active);
  flex-wrap: wrap;
}
.actionIncWrap .actionIncItem {
  border-bottom: 1px solid var(--basis-colors-base-active);
  border-bottom-style: dashed;
  margin-right: 16px;
}
.actionIncWrap .actionIncItem:hover {
  cursor: pointer;
}

.change-inc-action .ant-modal-body {
  padding: 16px;
}
.change-inc-action .ant-modal-body .Textarea {
  width: 100%;
}
.change-inc-action .ant-modal-body .Form-Item {
  margin-top: 24px;
}
.change-inc-action .ant-modal-body .Form-Item:first-child {
  margin-top: 0;
}
.change-inc-action .ant-modal-body .Form-Item .Form-Field {
  margin: 0;
}

.aboutNCCCIWrap {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: var(--basis-colors-text-secondary);
}
.aboutNCCCIWrap .iconMonitoringWrap {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.aboutNCCCIWrap .iconMonitoringWrap i {
  display: block;
}
.aboutNCCCIWrap .aboutNCCCIText {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
}

.IncidentListPanel.IncidentPagePanel {
  border-right: 1px solid var(--basis-colors-borders-normal);
  border-left: 0;
}