.SearchPanel {
  justify-content: center;
  background-color: var(--basis-colors-base-block);
  box-shadow: 0px 1px 0px 1px var(--basis-colors-shadows-default);
}
.SearchPanel-Sizer {
  flex-direction: column;
  max-width: 1440px;
  min-width: 608px;
  width: 100%;
  padding: 16px;
}
.SearchPanel-Filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
}
.SearchPanel-Filters-AddButton {
  margin-top: 8px;
}
.SearchPanel-Filters .Filter {
  align-items: center;
  height: 40px;
  margin: 8px 8px 0 0;
  padding: 10px 0 10px 16px;
  border: 1px solid var(--basis-colors-borders-normal);
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
}
.SearchPanel-Filters .Filter:focus-visible {
  outline: none;
}
.SearchPanel-Filters .Filter-Active {
  border-color: var(--basis-colors-base-active);
  box-shadow: 0 0 0 3px var(--components-button-colors-shadow-focused);
}
.SearchPanel-Filters .Filter-Label {
  color: var(--basis-colors-text-secondary);
  white-space: nowrap;
  margin-right: 8px;
}
.SearchPanel-Filters .Filter-RemoveButtonIcon {
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 8px;
}
.SearchPanel-Filters .Filter-RemoveButtonIcon:hover {
  background-color: var(--basis-colors-base-block);
}
.SearchPanel-Filters .Filter:hover {
  border-color: var(--basis-colors-base-active);
}
.SearchPanel-Filters .IconButton {
  margin-top: 8px;
  border-radius: 4px;
}
.SearchPanel .Search,
.SearchPanel .SearchInput,
.SearchPanel .FilterButton,
.SearchPanel .Sort {
  margin-right: 8px;
}
.SearchPanel .SearchInput {
  width: 100%;
}
.SearchPanel .Search {
  width: 100%;
}
.SearchPanel .FilterButton {
  border: 1px solid var(--basis-colors-borders-normal);
}
.SearchPanel .Sort {
  background-color: var(--basis-colors-base-block);
}
.SearchPanel .Sort .SortButton {
  display: flex;
  justify-content: center;
  border-radius: 4px 0 0 4px;
  width: 40px;
  background-color: inherit;
  color: #a3a3a3;
  border: 1px solid var(--basis-colors-borders-normal);
}
.SearchPanel .Sort .SortButton:hover {
  background-color: var(--basis-colors-button-transparent-hover);
}
.SearchPanel .Sort .SortButton .Icon {
  display: flex;
}
.SearchPanel .Sort .SortButton:hover .Icon {
  color: #717171;
}
.SearchPanel .Sort .SortControl {
  display: flex;
  justify-content: center;
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
  padding: 0;
  width: 25px;
  background-color: inherit;
  border: 1px solid var(--basis-colors-borders-normal);
}
.SearchPanel .Sort .SortControl .left_icon {
  width: 17px;
  height: 17px;
}
.SearchPanel .Sort .SortControl:hover {
  background-color: var(--basis-colors-button-transparent-hover);
}
.SearchPanel .MenuButton {
  padding: 0;
}
.SearchPanel .MenuButton .rules {
  width: 89px;
}
.SearchPanel .MenuButton .mitre {
  width: 130px;
}

.Panel-Popover-Container {
  max-height: 600px;
  max-width: 300px;
}
.Panel-Popover-Container .Dropdown-List {
  width: 100%;
  max-height: 494px;
  overflow-y: scroll;
  margin: 8px 0;
  color: red;
}
.Panel-Popover-Container .Panel-Filter-Search {
  margin: 0 16px;
}
.Panel-Popover-Container .Panel-Filter-Search-Input {
  margin: 8px 0;
}
.Panel-Popover-Container .Panel-Filter-Search-SelectAll {
  display: inline-block;
  font-size: 14px;
  color: var(--basis-colors-base-active) !important;
  cursor: pointer;
  border-bottom: 1px dashed var(--basis-colors-base-active);
  padding: 0 0 2px;
  margin: 8px 0;
}
.Panel-Popover-Container .Panel-Filter-Search-Separate {
  height: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 8px -16px 0 -16px;
}
.Panel-Popover-Container .Panel-Filter-Search-Highlight {
  background-color: rgba(235, 111, 28, 0.2);
}

.chakra-portal > div {
  z-index: 1000;
}

.Search-Button,
.Filter-Button,
.SearchPanel-Filters-AddButton {
  padding: 8px !important;
}