.Loader {
  color: var(--components-spinner-colors-default);
}

.Grid[data-test-id=OldGrid] .bz-icon-multi-checkbox,
.Grid[data-test-id=OldGrid] .bz-icon-multi-checkbox-some-outline {
  color: var(--basis-colors-icon-active-normal) !important;
}
.Grid[data-test-id=OldGrid] .Overlay {
  background-color: var(--components-overlay-colors-light);
}
.Grid[data-test-id=OldGrid] .Label_primary {
  background-color: var(--basis-colors-base-active);
}

.Checkbox .Checkbox-Icon {
  color: var(--components-checkbox-colors-off-default);
}
.Checkbox .Checkbox-Icon:hover {
  color: var(--components-checkbox-colors-off-hover);
}

.Checkbox_checked .Checkbox-Icon {
  color: var(--components-checkbox-colors-on-default);
}
.Checkbox_checked .Checkbox-Icon:hover {
  color: var(--components-checkbox-colors-on-hover);
}

.Select-Control,
.Select_multiple-ToggleInput input,
.Textarea {
  color: var(--components-textarea-colors-text-default);
  border: 1px solid var(--components-textarea-colors-border-default);
  background: var(--components-textarea-colors-background-default);
  box-shadow: var(--components-textarea-shadow-focus) 0px 0px 0px 3px !important;
  outline: none;
}

.Select-Control:focus,
.Select_multiple-ToggleInput_focus input,
.Textarea:focus {
  border: 1px solid var(--components-textarea-colors-border-default);
  box-shadow: var(--components-textarea-shadow-focus) 0px 0px 0px 3px !important;
  outline: none;
}

.Link,
.Grid-FilterIcon.Grid-FilterIcon_active {
  color: var(--basis-colors-base-active);
}

.Link:hover {
  color: var(--basis-colors-link-hover);
}

.Node.Node_selected,
.Select_multiple-Checkbox_checked,
.Filter-Checkbox_checked {
  background-color: var(--components-node-colors-select) !important;
}
.Node.Node_selected:hover,
.Select_multiple-Checkbox_checked:hover,
.Filter-Checkbox_checked:hover {
  background-color: var(--components-node-colors-select-hover) !important;
}

.Node:hover {
  background-color: var(--components-node-colors-hover);
}
.Node_selected .Node-CheckboxIcon {
  color: var(--basis-colors-icon-active-normal);
}

.Select_multiple-Counter {
  background-color: var(--components-badge-colors-secondary-default);
}
.Select .Icon {
  color: var(--basis-colors-icon-normal);
}
.Select .Icon.bz-icon-angle-up {
  color: var(--basis-colors-icon-hover);
}

.Button,
.Button_basic {
  background: var(--components-button-colors-transparent-default);
  --button-icon-opacity: unset;
}
.Button:hover,
.Button_basic:hover {
  background-color: var(--components-button-colors-transparent-hover);
}
.Button .Button-Text,
.Button_basic .Button-Text {
  color: var(--components-button-colors-transparent-text-default-default) !important;
}

.Button-Text {
  color: var(--components-button-colors-secondary-text-default) !important;
}

.Layout-Container .Button-Icon:hover,
.Layout-Container .IconButton:hover {
  color: var(--components-button-colors-transparent-icon-default-hover);
}
.Layout-Container .Button-Icon:disabled,
.Layout-Container .IconButton:disabled {
  color: var(--components-button-colors-secondary-icon-deafult-disabled);
}
.Layout-Container .Button,
.Layout-Container .Button_basic {
  --button-shadow-default: unset;
  background-color: var(--components-button-colors-transparent-with-border-default);
  border: var(--components-button-colors-transparent-with-border-border);
}
.Layout-Container .Button:hover,
.Layout-Container .Button_basic:hover {
  background-color: var(--components-button-colors-transparent-with-border-hover);
}
.Layout-Container .Button:focus,
.Layout-Container .Button_basic:focus {
  outline: none;
  border: var(--components-button-colors-transparent-with-border-border-focus);
  box-shadow: 0 0 0 3px var(--components-button-colors-shadow-focused);
}
.Layout-Container .Button .Text,
.Layout-Container .Button_basic .Text {
  color: var(--components-button-colors-transparent-with-border-text-default-default) !important;
}

.IconButton,
.Button-Icon {
  color: var(--components-button-colors-transparent-icon-default-normal);
}

.Button_primary.Button_rich {
  background-color: var(--components-button-colors-primary-default);
}
.Button_primary.Button_rich .Text {
  color: var(--basis-colors-text-default) !important;
}

.Button_primary.Button_rich:hover {
  background-color: var(--components-button-colors-primary-hover);
}

.ant-collapse {
  color: var(--basis-colors-text-default);
}

.ant-collapse-content,
.ant-collapse-borderless {
  background: var(--basis-colors-base-block) !important;
}

.Header {
  color: var(--basis-colors-text-default);
}

.Subheader {
  color: var(--basis-colors-text-default);
}

.ant-collapse-content,
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: var(--basis-colors-text-default) !important;
}

.IncidentListPanel .IncidentListPanelHeader,
.ant-collapse-item {
  border-color: var(--basis-colors-borders-normal) !important;
  border-bottom: 1px solid var(--basis-colors-borders-normal);
}

.Layout-BreadCrumb,
.LogSources .LogSourcesHeader,
.LogSourcesLeftPanel,
.ReportsPanel,
.DocumentationPanel,
.InstructionsPanel,
.Instructions .InstructionsHeader {
  background: var(--basis-colors-base-block) !important;
}

.Layout-BreadCrumb {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
}

.TagGroup .Tag {
  background-color: var(--components-badge-colors-secondary-default);
  color: var(--components-bagde-colors-secondary-text-default);
  border: 1px solid var(--components-badge-colors-secondary-default);
}

.TagGroup span {
  padding: 0 4px;
}

.Block {
  background: var(--basis-colors-base-block);
  border: 1px solid var(--components-block-colors-border);
}
.Block-Header {
  border-color: var(--basis-colors-borders-normal);
}

.Text {
  color: var(--basis-colors-text-default);
}

.Grid-FilterIcon {
  color: var(--basis-colors-icon-normal);
}
.Grid-FilterIcon:hover {
  color: var(--basis-colors-icon-hover);
}
.Grid-SortIcon {
  color: var(--basis-colors-icon-normal);
}
.Grid-HeaderCell_sorting .Grid-ColumnTitle {
  color: var(--basis-colors-text-default);
}
.Grid-Header {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
}
.Grid-Cell_hovered {
  background: var(--basis-colors-node-hover);
  color: var(--basis-colors-text-default) !important;
}
.Grid .Overlay {
  z-index: 1;
}
.Grid-Cell_selected {
  background-color: var(--basis-colors-node-select);
}
.Grid-Cell_selected.Grid-Cell_hovered {
  background-color: var(--basis-colors-node-select-hover) !important;
  --actions-background: var(--basis-colors-node-select-hover) !important;
}

.Actions-Stack,
.Actions-Stack::before {
  background-color: inherit;
  box-shadow: unset;
}

.TabItem {
  color: var(--components-tab-colors-text-default) !important;
}
.TabItem svg {
  color: var(--basis-colors-icon-normal);
}
.TabItem:hover {
  border-color: var(--basis-colors-borders-normal) !important;
}

.ant-alert {
  background-color: var(--basis-colors-base-block);
  border-color: var(--basis-colors-borders-normal);
}
.ant-alert-message {
  color: var(--basis-colors-text-default);
}
.ant-alert .anticon-close {
  color: var(--basis-colors-icon-normal);
}

.ant-popover-arrow-content, .ant-popover-inner {
  background-color: var(--basis-colors-base-block) !important;
  border: 1px solid var(--basis-colors-borders-normal);
}
.ant-popover-arrow-content-content, .ant-popover-inner-content {
  color: var(--basis-colors-text-default);
}

.Tooltip {
  background-color: var(--components-tooltip-colors-default-background);
  box-shadow: 0 0 3px 0 var(--components-tooltip-colors-inverted-border);
}
.Tooltip .Text,
.Tooltip span {
  color: var(--components-tooltip-colors-default-text);
}

.Input {
  --input-shadow-default: unset;
  --input-shadow-hover: unset;
  --input-shadow-focus: unset;
  --input-shadow-focus-outer: unset;
}
.Input .Input-Control {
  color: var(--components-input-colors-text-default);
  --input-placeholder-color: var(--components-input-colors-text-placeholder);
  border: 1px solid var(--components-input-colors-border-default);
  background: var(--components-input-colors-background-default);
}
.Input .Input-Control:hover {
  border: 1px solid var(--components-input-colors-border-hover);
}
.Input .Input-Control:focus {
  outline: none;
  border: 1px solid var(--components-input-colors-border-focus);
  box-shadow: 0 0 0 3px var(--components-input-colors-shadow-focus);
}
.Input .Input-Clear {
  color: var(--basis-colors-icon-normal);
}
.Input .Input-Icon,
.Input .Input-PassEye {
  color: var(--components-input-colors-action-icon-default) !important;
}

.FilterControl .IconButton {
  color: var(--components-button-colors-transparent-with-border-icon-default-normal);
  background-color: var(--components-button-colors-transparent-with-border-default);
  border: var(--components-button-colors-transparent-with-border-border);
}
.FilterControl .IconButton:hover {
  color: var(--components-button-colors-transparent-with-border-icon-default-normal);
  background-color: var(--components-button-colors-transparent-with-border-hover);
}
.FilterControl .IconButton:focus {
  border: var(--components-button-colors-transparent-with-border-border-focus);
  box-shadow: 0 0 0 3px var(--components-button-colors-shadow-focused);
}
.FilterControl .IconButton:disabled {
  background-color: var(--components-button-colors-transparent-with-border-disabled);
}
.FilterControl_colored .Icon {
  color: var(--basis-colors-icon-active-normal) !important;
}

.FilterTag:focus,
.IconButton:focus {
  border: var(--components-button-colors-transparent-with-border-border-focus) !important;
  box-shadow: 0 0 0 3px var(--components-button-colors-shadow-focused) !important;
}

.SortControl,
.SortControl .IconButton {
  border-color: var(--basis-colors-borders-normal) !important;
}

.SortControl .Button-Icon,
.SortControl .IconButton,
.FilterPanel .Button-Icon,
.FilterPanel .IconButton {
  color: var(--components-button-colors-transparent-with-border-icon-default-normal);
  background-color: var(--components-button-colors-transparent-with-border-default);
}
.SortControl .Button-Icon:hover,
.SortControl .IconButton:hover,
.FilterPanel .Button-Icon:hover,
.FilterPanel .IconButton:hover {
  background-color: var(--components-button-colors-transparent-with-border-hover);
}
.SortControl .Button-Icon:disabled,
.SortControl .IconButton:disabled,
.FilterPanel .Button-Icon:disabled,
.FilterPanel .IconButton:disabled {
  background-color: var(--components-button-colors-transparent-with-border-disabled);
}

.Dropdown {
  background-color: var(--basis-colors-node-normal);
  border: 1px solid var(--basis-colors-borders-normal);
  box-shadow: 0 0 4px 0 var(--basis-colors-shadows-default);
}
.Dropdown .Node {
  background-color: var(--basis-colors-node-normal);
}
.Dropdown .Node_selected {
  background-color: var(--basis-colors-node-select);
}
.Dropdown .Node_selected:hover {
  background-color: var(--basis-colors-node-select-hover) !important;
}
.Dropdown .Node:hover {
  background-color: var(--basis-colors-node-hover);
}
.Dropdown-List-Node-Selected {
  background-color: var(--basis-colors-node-select) !important;
}
.Dropdown-List-Node-Selected:hover {
  background-color: var(--basis-colors-node-select-hover) !important;
}

.FilterTag {
  background-color: var(--basis-colors-node-normal) !important;
  border: 1px solid var(--basis-colors-borders-normal) !important;
}
.FilterTag:hover, .FilterTag:focus {
  background-color: var(--basis-colors-node-normal) !important;
  border-color: var(--basis-colors-base-active) !important;
}
.FilterTag-Title {
  color: var(--basis-colors-text-secondary) !important;
}
.FilterTag-Text {
  color: var(--basis-colors-text-default);
}
.FilterTag-Icon {
  color: var(--basis-colors-icon-normal) !important;
}

.ant-calendar {
  background-color: var(--basis-colors-node-normal) !important;
  color: var(--basis-colors-text-default);
  border-color: var(--basis-colors-borders-normal) !important;
  box-shadow: 0 2px 8px var(--basis-colors-shadows-default) !important;
}
.ant-calendar-body, .ant-calendar-footer {
  border-color: var(--basis-colors-borders-normal) !important;
}
.ant-calendar-prev-year-btn:hover::before, .ant-calendar-prev-year-btn:hover::after, .ant-calendar-prev-month-btn:hover::before, .ant-calendar-prev-month-btn:hover::after, .ant-calendar-next-year-btn:hover::before, .ant-calendar-next-year-btn:hover::after, .ant-calendar-next-month-btn:hover::before, .ant-calendar-next-month-btn:hover::after, .ant-calendar-month-panel-prev-year-btn:hover::before, .ant-calendar-month-panel-prev-year-btn:hover::after, .ant-calendar-month-panel-next-year-btn:hover::before, .ant-calendar-month-panel-next-year-btn:hover::after, .ant-calendar-year-panel-prev-decade-btn:hover::before, .ant-calendar-year-panel-prev-decade-btn:hover::after, .ant-calendar-year-panel-next-decade-btn:hover::before, .ant-calendar-year-panel-next-decade-btn:hover::after {
  border-color: var(--basis-colors-icon-hover) !important;
}
.ant-calendar-prev-year-btn::before, .ant-calendar-prev-year-btn::after, .ant-calendar-prev-month-btn::before, .ant-calendar-prev-month-btn::after, .ant-calendar-next-year-btn::before, .ant-calendar-next-year-btn::after, .ant-calendar-next-month-btn::before, .ant-calendar-next-month-btn::after, .ant-calendar-month-panel-prev-year-btn::before, .ant-calendar-month-panel-prev-year-btn::after, .ant-calendar-month-panel-next-year-btn::before, .ant-calendar-month-panel-next-year-btn::after, .ant-calendar-year-panel-prev-decade-btn::before, .ant-calendar-year-panel-prev-decade-btn::after, .ant-calendar-year-panel-next-decade-btn::before, .ant-calendar-year-panel-next-decade-btn::after {
  border-color: var(--basis-colors-icon-normal) !important;
}
.ant-calendar-month-select, .ant-calendar-year-select, .ant-calendar-month-panel-year-select, .ant-calendar-year-panel-decade-select {
  color: var(--basis-colors-text-default) !important;
}
.ant-calendar-last-month-cell div, .ant-calendar-next-month-btn-day div {
  color: var(--basis-colors-text-placeholder) !important;
}
.ant-calendar .ant-calendar-today.ant-calendar-last-month-cell div {
  color: var(--basis-colors-text-placeholder) !important;
  border-color: transparent !important;
}
.ant-calendar-today div {
  color: var(--basis-colors-base-active) !important;
  border-color: var(--basis-colors-base-active) !important;
}
.ant-calendar-selected-start-date div, .ant-calendar-selected-end-date div {
  background-color: var(--basis-colors-base-active) !important;
  color: var(--basis-colors-text-inverted) !important;
}
.ant-calendar-in-range-cell::before {
  background-color: var(--basis-colors-node-select) !important;
}
.ant-calendar-date {
  color: var(--basis-colors-text-default) !important;
}
.ant-calendar-date:hover {
  background-color: var(--basis-colors-base-active) !important;
  color: var(--basis-colors-text-inverted) !important;
}
.ant-calendar .ant-tag {
  color: var(--components-button-colors-transparent-with-border-text-active-default);
  background-color: var(--components-button-colors-transparent-with-border-default);
  border: var(--components-button-colors-transparent-with-border-border);
}
.ant-calendar .ant-tag:hover {
  background-color: var(--components-button-colors-transparent-with-border-hover) !important;
}
.ant-calendar-month-panel, .ant-calendar-year-panel {
  background-color: var(--basis-colors-node-normal) !important;
  box-shadow: 0 0 4px 0 var(--basis-colors-shadows-default) !important;
}
.ant-calendar-month-panel-header, .ant-calendar-year-panel-header {
  border: 1px solid var(--basis-colors-borders-normal);
}
.ant-calendar-month-panel-body, .ant-calendar-year-panel-body {
  background-color: var(--basis-colors-node-normal) !important;
  border-color: var(--basis-colors-borders-normal) !important;
  border: 1px solid var(--basis-colors-borders-normal);
}
.ant-calendar-month-panel-cell a, .ant-calendar-year-panel-cell a {
  background-color: var(--components-button-colors-transparent-with-border-default) !important;
  color: var(--components-button-colors-transparent-with-border-text-default-default) !important;
}
.ant-calendar-month-panel-cell a:hover, .ant-calendar-year-panel-cell a:hover {
  background-color: var(--components-button-colors-transparent-with-border-hover) !important;
}
.ant-calendar-month-panel-cell-disabled a, .ant-calendar-year-panel-cell-disabled a {
  background-color: var(--components-button-colors-transparent-with-border-disabled) !important;
  color: var(--components-button-colors-transparent-with-border-text-default-disabled) !important;
}
.ant-calendar-month-panel-cell-disabled a:hover, .ant-calendar-year-panel-cell-disabled a:hover {
  background-color: unset !important;
}
.ant-calendar-month-panel-selected-cell a, .ant-calendar-year-panel-selected-cell a {
  background-color: var(--components-button-colors-primary-default) !important;
}
.ant-calendar-month-panel-selected-cell a:hover, .ant-calendar-year-panel-selected-cell a:hover {
  background-color: var(--components-button-colors-primary-hover) !important;
}

.ant-picker {
  background-color: var(--components-input-colors-background-default);
  border: 1px solid var(--components-input-colors-border-default);
  color: var(--components-input-colors-text-default);
  height: var(--components-input-sizes-md-height);
}
.ant-picker:hover {
  border-color: var(--components-input-colors-border-hover);
}
.ant-picker:focus {
  border-color: var(--components-input-colors-border-focus);
  box-shadow: 0 0 0 2px var(--components-input-colors-shadow-focus);
}
.ant-picker-suffix {
  color: var(--basis-colors-icon-default);
}

.MarkdownHtml {
  font-family: var(--basis-typography-fonts-default) !important;
  color: var(--basis-colors-text-default) !important;
}
.MarkdownHtml code {
  text-wrap: auto !important;
  background-color: var(--components-badge-colors-secondary-default) !important;
  border: 1px solid var(--basis-colors-borders-normal) !important;
  color: var(--components-badge-colors-secondary-text-default);
}

.ant-tabs {
  color: var(--basis-colors-text-default) !important;
}

.ant-modal-close {
  z-index: 1;
}
.ant-modal-content, .ant-modal-header {
  background-color: var(--basis-colors-base-block) !important;
}
.ant-modal-header, .ant-modal-footer {
  border-color: var(--basis-colors-borders-normal) !important;
}
.ant-modal-close-x {
  color: var(--basis-colors-icon-normal);
}
.ant-modal-close-x:hover {
  color: var(--basis-colors-icon-hover);
}
.ant-modal .Button {
  --button-shadow-default: unset;
  border: var(--components-button-colors-transparent-with-border-border);
}
.ant-modal .VersionSection-CommentSection, .ant-modal .VersionSection-CommentHeader {
  color: var(--basis-colors-text-default);
}
.ant-modal .Info .Icon {
  color: var(--basis-colors-icon-normal);
}

.List-Key {
  color: var(--basis-colors-text-secondary);
}
.List-Key .Text {
  color: var(--basis-colors-text-secondary);
}
.List-Value {
  color: var(--basis-colors-text-default);
}

.RowWithIcon .Icon {
  color: var(--basis-colors-icon-normal);
}

.Message_warning,
.Message_error {
  background-color: var(--basis-colors-base-block);
  border-color: var(--basis-colors-base-attention);
}

.Breadcrumbs .Link > .Text,
.Breadcrumbs .Breadcrumb-Icon {
  color: var(--basis-colors-link-normal);
}
.Breadcrumbs-Divider {
  color: var(--basis-colors-icon-normal);
}

.NodeDetails-SubTitle {
  color: var(--basis-colors-text-secondary) !important;
}
.NodeDetails-Text {
  color: var(--basis-colors-text-default) !important;
}

.ant-progress-text {
  color: var(--basis-colors-text-default);
}

.ant-message-notice-content {
  background-color: var(--basis-colors-base-block);
  color: var(--basis-colors-text-default);
  border: 1px solid var(--basis-colors-icon-warning-normal);
  box-shadow: 0 0 4px 0 var(--basis-colors-icon-warning-normal);
}
.ant-message-notice-content svg {
  fill: var(--basis-colors-icon-warning-normal);
}

[class^=KeyValueRichItem__MoreButton] {
  color: var(--basis-colors-link-normal) !important;
  border-bottom: 1px dashed var(--basis-colors-link-normal) !important;
}

.RadioGroup-Radio_active {
  --radio-icon-color: var(--basis-colors-icon-active-normal);
  --radio-icon-color-hover: var(--basis-colors-icon-active-hover);
}