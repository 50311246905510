.reports-title {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-family: var(--basis-typography-fonts-default);
}

.reports-card {
  margin: 16px !important;
}

.reports-create {
  display: inline-block;
  margin-right: 5px;
}

.reports-search {
  display: inline-block;
  width: auto;
}