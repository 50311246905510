.read-more-btn {
  margin-top: 10px;
}
.read-more-btn span {
  cursor: pointer;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.65);
}
.read-more-btn span:hover {
  color: black;
  border-bottom-color: black;
}
.read-more-btn span:active {
  border-bottom-style: solid;
}