.AlertPanelSection .AlertPanelSectionItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 9px 0px 14px;
}
.AlertPanelSection .AlertPanelSectionItem-Key {
  display: flex;
  flex-direction: column;
  width: 128px;
}
.AlertPanelSection .AlertPanelSectionItem-Value {
  margin-left: 25px;
  flex-grow: 1;
  word-break: break-word;
}
.AlertPanelSection .AlertPanelSectionItem-AlternativeKey {
  display: block;
  font-size: 12px;
}
.AlertPanelSection .AlertPanelSectionItem-Vertical {
  display: flex;
  flex-direction: column;
  padding: 9px 0px 14px;
}
.AlertPanelSection .AlertPanelSectionItem-Vertical .AlertPanelSectionItem-Key {
  width: unset;
  padding-bottom: 8px;
}