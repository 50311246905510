.ReadMitreSoc {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-auto-rows: minmax(20px, auto);
  grid-row-gap: 16px;
}
.ReadMitreSoc-Cell {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}