.inc-card {
  padding: 0 !important;
  height: 100%;
  overflow: inherit !important;
}
.inc-card::before {
  display: none !important;
}
.inc-card section + section {
  margin-top: 16px;
}
.inc-card-content {
  max-width: none;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  padding: 0;
}
.inc-card-content::after {
  display: none;
}
.inc-card-left-block {
  margin-right: 16px;
  flex: 0 0 420px;
  overflow-y: auto;
}
.inc-card .relation-tab {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-right: 16px;
}
.inc-card-center-block {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 480px;
}
.inc-card-center-block .Block {
  min-width: auto;
}
.inc-card-center-block .ant-tabs-bar {
  padding-top: 11px;
  margin: 0;
}
.inc-card-center-block .ant-tabs-nav {
  margin-right: 16px;
}
.inc-card-center-block .ant-tabs-nav:before {
  border-color: var(--basis-colors-borders-normal);
}
.inc-card-center-block .ant-tabs-nav .ant-tabs-tab-disabled {
  cursor: default;
  color: var(--components-tab-colors-text-disabled);
}
.inc-card-center-block .ant-tabs-content {
  height: calc(100vh - 112px);
}
.inc-card-center-block .ant-tabs-content .inc-card-info {
  width: 100%;
  padding-right: 16px;
}
.inc-card-center-block .ant-tabs-content .inc-card-info.inc-card-details, .inc-card-center-block .ant-tabs-content .inc-card-info.inc-card-iocs, .inc-card-center-block .ant-tabs-content .inc-card-info.inc-card-assets, .inc-card-center-block .ant-tabs-content .inc-card-info.inc-card-alerts {
  height: 100%;
  padding-bottom: 16px;
}
.inc-card-center-block .ant-tabs-content .inc-card-info.inc-card-alerts .Block {
  height: 100%;
}
.inc-card-center-block .ant-tabs-content .inc-card-info .inc-card-description {
  max-height: calc(100vh - 190px);
  overflow: auto;
}
.inc-card-center-block .ant-tabs-content .inc-card-info .inc-card-description span {
  color: var(--basis-colors-text-default);
}
.inc-card-center-block .ant-tabs-content .inc-card-info .inc-card-alerts-block {
  height: calc(100% - 68px);
}
.inc-card-center-block .ant-tabs-content .inc-card-info .inc-card-alerts-block .ListPageBody-Cards {
  padding: 0 8px 0 0;
}
.inc-card-right-block {
  flex: 0 0 400px;
}
.inc-card-description {
  padding: 32px 32px 72px;
}
.inc-card .mainInfo {
  display: flex;
  flex-direction: column;
}
.inc-card .mainInfo .SummarySection {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.inc-card .mainInfo .SummarySection .Stamp {
  display: flex;
  align-items: center;
}
.inc-card .mainInfo .SummarySection .Stamp-Logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}
.inc-card .mainInfo .SummarySection .Stamp-Text {
  color: var(--basis-colors-text-default);
}
.inc-card .mainInfo .divider {
  display: inline-block;
  height: 1px;
  clear: both;
  width: calc(100% + 64px);
  background: #e6e6e6;
  margin: 32px 0 32px -32px;
}
.inc-card .mainInfo > div:first-child {
  flex-grow: 1;
}
.inc-card .mainInfo > div:last-child {
  min-width: 200px;
  flex-shrink: 0;
}
.inc-card .CommentsBody {
  min-height: 556px;
  max-height: 556px;
  overflow: auto;
}
.inc-card .NoComments {
  --text-color: #999;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow: auto;
  padding: 96px 0 16px;
}
.inc-card .NoComments-AddButton {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 24px;
  max-width: 100%;
}
.inc-card .NCCCI .Button {
  border: 0;
}
.inc-card .NCCCI .Button .Button-Text {
  color: var(--components-button-colors-transparent-text-active-default) !important;
}

.TabPane_disabled {
  pointer-events: none;
}

.icon-option-wrap {
  display: flex;
  align-items: center;
}
.icon-option-wrap .text-option {
  margin-left: 8px;
}
.icon-option-wrap .Icon {
  font-size: 20px;
}

.ant-tabs-dropdown-menu {
  background-color: var(--basis-colors-base-block);
}
.ant-tabs-dropdown-menu-item {
  background-color: var(--basis-colors-node-normal);
  color: var(--basis-colors-text-default);
}
.ant-tabs-dropdown-menu-item:hover:not(.ant-tabs-dropdown-menu-item-disabled) {
  background-color: var(--basis-colors-node-hover);
}
.ant-tabs-dropdown-menu-item-disabled {
  color: var(--basis-colors-text-secondary);
}
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: var(--basis-colors-text-secondary);
}