@font-face {
  font-family: "OpenSans Light";
  font-style: normal;
  font-weight: 300;
  src: local("OpenSans Light"), local("OpenSans-Light"), url("./static/fonts/OpenSans/OpenSans-Light.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  src: local("OpenSans"), local("OpenSans-Regular"), url("./static/fonts/OpenSans/Open Sans.ttf") format("truetype"); /* Safari, Android, iOS */
}
/* roboto-300 - cyrillic_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("./static/fonts/roboto/roboto-v18-cyrillic_latin-300.eot"); /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("./static/fonts/roboto/roboto-v18-cyrillic_latin-300.eot?#iefix") format("embedded-opentype"), url("./static/fonts/roboto/roboto-v18-cyrillic_latin-300.woff2") format("woff2"), url("./static/fonts/roboto/roboto-v18-cyrillic_latin-300.woff") format("woff"), url("./static/fonts/roboto/roboto-v18-cyrillic_latin-300.ttf") format("truetype"), url("./static/fonts/roboto/roboto-v18-cyrillic_latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-regular - cyrillic_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./static/fonts/roboto/roboto-v18-cyrillic_latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("./static/fonts/roboto/roboto-v18-cyrillic_latin-regular.eot?#iefix") format("embedded-opentype"), url("./static/fonts/roboto/roboto-v18-cyrillic_latin-regular.woff2") format("woff2"), url("./static/fonts/roboto/roboto-v18-cyrillic_latin-regular.woff") format("woff"), url("./static/fonts/roboto/roboto-v18-cyrillic_latin-regular.ttf") format("truetype"), url("./static/fonts/roboto/roboto-v18-cyrillic_latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* ubuntu-300 - cyrillic_latin */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  src: url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-300.eot"); /* IE9 Compat Modes */
  src: local("Ubuntu Light"), local("Ubuntu-Light"), url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-300.eot?#iefix") format("embedded-opentype"), url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-300.woff2") format("woff2"), url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-300.woff") format("woff"), url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-300.ttf") format("truetype"), url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-300.svg#Ubuntu") format("svg"); /* Legacy iOS */
}
/* ubuntu-regular - cyrillic_latin */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Ubuntu Regular"), local("Ubuntu-Regular"), url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-regular.eot?#iefix") format("embedded-opentype"), url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-regular.woff2") format("woff2"), url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-regular.woff") format("woff"), url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-regular.ttf") format("truetype"), url("./static/fonts/ubuntu/ubuntu-v12-cyrillic_latin-regular.svg#Ubuntu") format("svg"); /* Legacy iOS */
}
.Input-Control,
.Select-Control,
.Link,
.Input,
.Select-Input,
.Textarea,
.Text,
.Tag,
div[class^=KeyValueRichItem__ValueWrapper],
div[class^=KeyValueRichItem__Key] {
  font-family: var(--basis-typography-fonts-default);
}

.Header,
.Subheader {
  font-family: var(--basis-typography-fonts-accent);
}

body {
  background: var(--basis-colors-base-desk) !important;
  margin: 0;
  padding: 0;
  overflow: hidden !important;
  min-width: 1280px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  font-family: var(--basis-typography-fonts-accent);
  color: var(--basis-colors-text-default);
}

b,
strong {
  font-weight: bold !important;
}

html {
  margin-left: calc(100vw - 100%);
  margin-right: 0;
}