.Factoids {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 16px;
  position: relative;
  z-index: 0;
  min-height: 96px;
  width: 100%;
}
.Factoids-SecondRow .Factoid + .Factoid {
  margin-left: 8px;
}

.Factoid {
  flex: 1 1 0;
  background-color: var(--basis-colors-base-block);
  border: 1px solid var(--basis-colors-base-borders-normal);
  border-radius: 4px;
  min-height: 136px;
  display: flex;
  justify-content: space-between;
  padding: 24px 22px 16px 25px;
}
.Factoid-Icon {
  margin-top: 12px;
  margin-right: 16px;
}
.Factoid-Data {
  color: var(--basis-colors-text-default);
  font-size: 36px;
  line-height: 1.4;
  font-weight: 500;
}
.Factoid-Description {
  color: var(--basis-colors-text-secondary);
  font-size: 14px;
  line-height: 1.43;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.Factoid-ExtraData {
  font-size: 20px;
  font-weight: 500;
}
.Factoid-ExtraData__top {
  color: var(--basis-colors-base-active);
}
.Factoid-ExtraData__bottom {
  color: var(--basis-colors-base-critical);
}
.Factoid-ExtraDescription {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--basis-colors-text-secondary);
}
.Factoid-InfoSection {
  flex: 1 1 315px;
}
.Factoid-ExtraInfo {
  color: #4d4d4d;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
  border-left: 1px solid var(--basis-colors-borders-normal);
  padding-left: 9%;
  flex: 1 1 210px;
}
.Factoid + .Factoid {
  margin-left: 16px;
}
.Factoid .Dynamics {
  font-weight: normal;
  font-size: 16px;
  vertical-align: top;
  position: relative;
  top: 0.6em;
  left: -0.4em;
}
.Factoid .Dynamics-Negative {
  color: var(--basis-colors-base-positive);
}
.Factoid .Dynamics-Positive {
  color: var(--basis-colors-base-critical);
}