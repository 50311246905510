@charset "UTF-8";
.IncidentCard {
  background-color: var(--components-card-colors-background-normal);
  border-radius: 4px;
  border: 1px solid var(--components-card-colors-border-normal);
  padding: 16px 24px 24px 24px;
  cursor: pointer;
  width: 100%;
  max-width: 1344px;
  margin: auto;
}
.IncidentCard:hover {
  box-shadow: 0 0 4px 0 var(--components-card-colors-border-selected);
}
.IncidentCard_notСlickable {
  cursor: auto;
}
.IncidentCard_selected {
  background-color: var(--components-card-colors-background-selected);
  border-color: var(--components-card-colors-border-selected);
}
.IncidentCard_Low {
  border-left: 4px solid #57ad37;
}
.IncidentCard_High {
  border-left: 4px solid #eb6f1c;
}
.IncidentCard_Medium {
  border-left: 4px solid #ebaf09;
}
.IncidentCard_Critical {
  border-left: 4px solid #e0281b;
}
.IncidentCard:not(:first-child) {
  margin-top: 16px;
}
.IncidentCard-Header {
  display: flex;
  flex-direction: column;
}
.IncidentCard-Header .Header {
  flex-grow: 1;
}
.IncidentCard-Date {
  color: var(--basis-colors-text-secondary);
  display: flex;
  margin-top: 4px;
}
.IncidentCard-Date > div {
  display: flex;
  align-items: center;
  line-height: 1;
}
.IncidentCard-Date > div i {
  margin-right: 3px;
}
.IncidentCard-Date div + div {
  margin-left: 10px;
}
.IncidentCard-Body {
  display: flex;
  margin-top: 12px;
}
.IncidentCard-FirstList {
  flex-basis: 360px;
  flex-shrink: 0;
}
.IncidentCard-SecondList {
  flex-grow: 1;
}
.IncidentCard-SecondList .List-Value {
  width: 100%;
}