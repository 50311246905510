.ChatFile {
  margin-right: 8px;
  height: 88px;
  width: 80px;
}
.ChatFile-Box {
  height: 80px;
  width: 80px;
  position: relative;
}
.ChatFile-Preview {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ChatFile-IconWrapper {
  height: 100%;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.ChatFile-FileDeleteOverlay {
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  position: absolute;
  inset: 0 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ChatFile-Title {
  max-width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ChatFile-Name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;
  max-width: 100%;
  flex: 1;
}
.ChatFile-Ext {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;
  max-width: 30px;
  min-width: 22px;
}