.dashboard-box-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-box-flex .box-body {
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  border-radius: 3px 0;
  padding: 10px;
}
.dashboard-box-flex .box-body .noData {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: var(--basis-colors-text-default);
}

.box {
  position: relative;
  border-radius: 4px;
  background: var(--basis-colors-base-block);
  border: 1px solid var(--basis-colors-base-borders-normal);
  margin-bottom: 20px;
  width: 100%;
}

.box-header {
  color: #444;
  padding: 19px 16px 16px 12px;
  position: relative;
  display: flex;
}
.box-header.with-border {
  border-bottom: 1px solid var(--basis-colors-base-borders-normal);
}
.box-header .box-title {
  display: flex;
  flex-grow: 1;
  font-size: 16px;
  margin: 0;
  line-height: 1;
}
.box-header .box-title .bz-icon {
  color: rgb(162, 162, 162);
  height: 24px;
  cursor: pointer;
}
.box-header .box-tools .Actions {
  display: flex;
  align-items: center;
  background-color: transparent;
}
.box-header .box-tools .Actions button {
  border-radius: 4px;
}
.box-header .box-tools .Actions-Stack {
  background-color: transparent;
  box-shadow: none;
}
.box-header .box-tools .Actions-Stack::before {
  background-color: transparent;
  box-shadow: none;
}

.btn {
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid transparent;
  cursor: pointer;
}

.btn-box-tool {
  font-size: 16px;
  background: transparent;
  color: #97a0b3;
}

.hide {
  display: none;
}

.box_fullScreen {
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  border: unset;
  border-radius: 0;
}
.box_fullScreen .box-header {
  padding-left: 24px;
}
.box_fullScreen .box-header .box-title .bz-icon {
  display: none;
}