.DocumentationPanel {
  background: #fff;
  box-shadow: 0 0 4px 0 var(--basis-colors-shadows-default);
  border-right: 1px solid var(--basis-colors-borders-normal);
  flex: 0 0 400px;
  overflow: auto;
  z-index: 4;
  transition: flex-basis 0.24s;
}
.DocumentationPanel .DocumentationPanelHeader {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
  padding: 12px 16px 12px 8px;
  display: flex;
  align-items: center;
}
.DocumentationPanel .DocumentationPanelHeader .Header {
  flex-grow: 1;
  word-break: break-word;
}
.DocumentationPanel .DocumentationPanelHeader-Controls {
  flex-shrink: 0;
}
.DocumentationPanel .PanelIcon {
  transition: transform 0.24s;
  height: 40px;
}
.DocumentationPanel .PanelIcon_active {
  transform: rotate(180deg);
}
.DocumentationPanel .TabItems-Container {
  display: block;
  flex-direction: column;
}
.DocumentationPanel .TabItems-Container .TabItem {
  display: flex;
  padding: 12px 16px !important;
  text-decoration: none;
  font-family: var(--basis-typography-fonts-default);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4d4d4d;
  cursor: pointer;
  visibility: visible;
}
.DocumentationPanel .TabItems-Container .TabItem:hover {
  padding: 12px 12px !important;
  border-left: 4px solid rgba(0, 0, 0, 0.1);
}
.DocumentationPanel .TabItems-Container .TabItem_active {
  padding: 12px 12px !important;
  border-left: 4px solid var(--basis-colors-base-active) !important;
}
.DocumentationPanel .TabItems-Container .TabItem-Text {
  opacity: 1;
  transition: opacity 0.5s ease-in;
  display: inline;
  visibility: visible;
  height: 20px;
}
.DocumentationPanel_closed {
  flex-basis: 65px;
  overflow: hidden;
}
@media only screen and (min-width: 1900px) {
  .DocumentationPanel_closed {
    transition: ease all 0.5s;
  }
}
.DocumentationPanel_closed .TabItems-Container .TabItem-Text {
  visibility: hidden;
  opacity: 0;
  width: 0;
}