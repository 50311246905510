.RulePagePanel {
  flex-basis: 460px;
  flex-shrink: 0;
  background: var(--basis-colors-base-block);
  box-shadow: 0 0 4px 0 var(--basis-colors-shadows-default);
  border-right: 1px solid var(--basis-colors-borders-normal);
}
.RulePagePanel-Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 4px 24px;
}
.RulePagePanel-Body {
  height: calc(100% - 108px);
  overflow-y: auto;
}
.RulePagePanel .PanelIcon {
  transition: transform 0.24s;
}
.RulePagePanel .PanelIcon_active {
  transform: rotate(180deg);
}
.RulePagePanel .ant-collapse-item {
  border-top: 1px solid #d9d9d9;
  border-bottom: none;
}
.RulePagePanel .ant-collapse-item .ant-collapse-content-box {
  padding: 16px 24px;
}
.RulePagePanel .ant-collapse-item .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 4px 16px 4px 24px;
}
.RulePagePanel .ant-collapse-item .ant-collapse-header .Header {
  flex-grow: 1;
}
.RulePagePanel .Layout-BreadCrumb .Icon {
  color: var(--basis-colors-base-active);
}
.RulePagePanel .Link .Text {
  color: var(--basis-colors-link-default);
}