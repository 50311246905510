.SortControl {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.SortControl .SortControl-Dropdown, .SortControl .SortControl-Toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.SortControl-Toggle {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  width: 40px;
}
.SortControl-Dropdown {
  width: 24px;
}