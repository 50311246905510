.CreateReportModal {
  z-index: 2;
}
.CreateReportModal .Periodicity {
  display: flex;
  justify-content: space-between;
}
.CreateReportModal .Periodicity .Form-Item {
  width: auto;
  flex-grow: 1;
  margin-right: 16px;
}
.CreateReportModal .Periodicity .DatePickerItem {
  margin-bottom: 24px;
}
.CreateReportModal .Periodicity .DatePickerItem-Label {
  margin: 0 0 7px;
}
.CreateReportModal .Info {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}
.CreateReportModal .Info .Text {
  padding-left: 6px;
  font-size: 14px;
  line-height: 1.43;
  text-align: justify;
}