.IncidentCommentCard {
  border: none !important;
  padding: 8px !important;
  gap: 8px !important;
  margin-bottom: 8px;
}
.IncidentCommentCard:focus-within {
  background-color: #fff !important;
}
.IncidentCommentCard:hover {
  cursor: auto;
}
.IncidentCommentCard-Time {
  display: flex;
}
.IncidentCommentCard-Time div + div {
  margin-left: 13px;
}
.IncidentCommentCard-Time .icon {
  margin-right: 3px;
}
.IncidentCommentCard-UserIcon {
  margin-top: 4px;
}