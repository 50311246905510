.responseInstruction-card {
  color: var(--basis-colors-text-default);
}
.responseInstruction-card section + section {
  margin-top: 16px;
}
.responseInstruction-card .mainInfo {
  display: flex;
}
.responseInstruction-card .mainInfo > div:first-child {
  flex-grow: 1;
}
.responseInstruction-card .mainInfo > div:last-child {
  min-width: 200px;
  flex-shrink: 0;
}