.EventsPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  flex: 0 0 400px;
  z-index: 4;
  transition: flex-basis 0.24s;
}
.EventsPanel .EventsPanelHeader {
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px 12px 8px;
  color: #7f98b6;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.EventsPanel .Events-Items {
  overflow-y: auto;
}
.EventsPanel_closed {
  flex-basis: 65px;
  overflow: hidden;
}
.EventsPanel_closed .Events-Items {
  display: none;
}
.EventsPanel .PanelIcon {
  transition: transform 0.24s;
  height: 40px;
  color: #7f98b6;
}
.EventsPanel .PanelIcon_active {
  transform: rotate(180deg);
}
.EventsPanel .EventsItem {
  display: block;
  position: relative;
  padding: 10px 15px 20px 35px;
  box-shadow: none;
  transition: box-shadow 0.2s linear;
  margin-right: 4px;
  text-transform: capitalize;
}
.EventsPanel .EventsItem_active {
  border-left: 4px solid var(--basis-colors-base-active) !important;
}