.main-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  align-content: space-between;
}

.login-header {
  background-color: var(--basis-colors-base-navbar-default);
  min-width: 1440px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.login-header .ant-menu {
  background-color: var(--basis-colors-base-navbar-default) !important;
}

.main-logo {
  display: flex;
  align-items: center;
  padding-right: 8px;
}
.main-logo img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 8px;
}
.main-logo span {
  width: 148px;
  height: 24px;
  font-family: var(--basis-typography-fonts-accent);
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: nowrap;
  color: var(--basis-colors-text-light);
}

.UserBlock {
  display: flex;
  align-items: center;
}
.UserBlock svg {
  font-size: 20px;
  color: var(--basis-colors-base-text-default);
  opacity: 0.8;
}
.UserBlock svg:hover {
  opacity: 1;
}
.UserBlock span {
  font-family: var(--basis-typography-fonts-accent);
}

.dropdown-icon {
  display: inline-block;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228%22%20height%3D%224%22%20viewBox%3D%220%200%208%204%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23FFF%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20d%3D%22M.8.7L4%203.3%207.2.7%22%2F%3E%3C%2Fsvg%3E") no-repeat;
  background-position: right 0;
  width: 18px;
  height: 4px;
  opacity: 0.45;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-active .dropdown-icon {
  opacity: 1;
}

.Block {
  overflow: unset !important;
}

.Select_multiple-Counter {
  line-height: 20px;
}

.LabelWithIcon {
  display: flex;
  align-items: center;
  color: #999999;
}
.LabelWithIcon-Icon {
  line-height: 14px;
  height: 14px;
  margin-left: 8px;
}
.LabelWithIcon-Icon i {
  cursor: pointer;
  color: inherit;
}

.ItemWithIcon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ItemWithIcon svg {
  opacity: 0.8;
}

.Main-Menu {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  background-color: var(--basis-colors-base-navbar-default) !important;
}
.Main-Menu li.ant-menu-item.ant-menu-item-only-child {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Main-Menu li.ant-menu-item.ant-menu-item-only-child:hover {
  background-color: var(--basis-colors-base-navbar-active) !important;
}
.Main-Menu li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: var(--basis-colors-base-navbar-active) !important;
}

.Sub-Menu {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 48px;
}
.Sub-Menu li.ant-menu-item.ant-menu-item-only-child {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.Sub-Menu li.ant-menu-item.ant-menu-item-only-child:hover {
  background-color: var(--basis-colors-base-navbar-active) !important;
}
.Sub-Menu li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: var(--basis-colors-base-navbar-active) !important;
}

.ant-menu-submenu-title {
  padding: 0;
}

button {
  outline: none;
}

.Divider {
  width: 1px;
  height: 24px;
  background: var(--basis-colors-text-inverted);
  opacity: 0.4;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-dark {
  margin-top: 15px;
}