.Instructions {
  justify-content: flex-start;
}
.Instructions .InstructionsHeader {
  width: 100%;
  background: var(--basis-colors-base-block);
  padding: 24px 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
}
.Instructions .InstructionsHeader-ControlsRow {
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-width: 1344px;
  margin: auto;
}
.Instructions .InstructionsHeader-ControlsRow > :not(:first-child) {
  margin-left: 8px;
}
.Instructions .InstructionsHeader-Search {
  display: flex;
  flex-grow: 1;
}
.Instructions .InstructionsHeader-Search button {
  margin-left: 8px;
}
.Instructions .InstructionsHeader-FiltersRow {
  width: 100%;
  max-width: 1344px;
  margin: auto;
}
.Instructions .InstructionsHeader-FiltersRow .FilterPanel {
  margin-top: 8px;
}
.Instructions .InstructionsContainer {
  padding: 0;
  max-width: unset;
  max-height: unset;
  display: flex;
  flex-direction: column;
}
.Instructions .InstructionsContainer .InstructionsBody {
  display: flex;
  position: relative;
  flex-grow: 1;
  min-height: 0;
}
.Instructions .InstructionsContainer .InstructionsBody-Cards {
  flex-grow: 1;
  min-height: 0;
  padding: 16px;
  overflow: auto;
}
.Instructions .InstructionsContainer .InstructionsBody_fetching {
  overflow: hidden;
}
.Instructions .InstructionsContainer .InstructionsBody_fetching .Overlay {
  background-clip: content-box;
  z-index: 1;
}
.Instructions .InstructionsContainer .InstructionsBody-NoData {
  --text-color: #999;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow: auto;
  padding: 96px 0 16px;
}