.RulePage {
  padding: 0;
  height: 100%;
  overflow: inherit;
}
.RulePage::before {
  display: none;
}
.RulePage section + section {
  margin-top: 16px;
}
.RulePage-Content {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  padding: 0;
}
.RulePage-Content::after {
  display: none;
}
.RulePage-Tabs {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 376px);
  padding: 0 16px;
}
.RulePage-Tabs .ant-tabs-bar {
  padding-top: 11px;
  margin: 0;
}
.RulePage-Tabs .ant-tabs-nav .ant-tabs-tab-disabled {
  cursor: default;
}
.RulePage-Tabs .ant-tabs-content {
  height: calc(100vh - 112px);
}
.RulePage-Tabs .ant-tabs-content .inc-card-info {
  width: 100%;
  padding-right: 16px;
}
.RulePage-Tabs .ant-tabs-content .inc-card-info.inc-card-details, .RulePage-Tabs .ant-tabs-content .inc-card-info.inc-card-iocs, .RulePage-Tabs .ant-tabs-content .inc-card-info.inc-card-assets {
  height: 100%;
  overflow: auto;
  padding-bottom: 16px;
}
.RulePage-Description {
  padding: 16px 32px 16px;
  white-space: pre-line;
}
.RulePage .Block-Header_separated {
  border-color: var(--basis-colors-borders-normal);
}