.NotificationBlock {
  display: block;
  position: fixed;
  z-index: 9002;
}
.NotificationBlock_topLeft {
  top: 40px;
  left: 0;
}
.NotificationBlock_topRight {
  top: 40px;
  right: 0;
}
.NotificationBlock_bottomLeft {
  bottom: 40px;
  left: 0;
}
.NotificationBlock_bottomRight {
  bottom: 40px;
  right: 0;
}
.NotificationBlock .Message-Close {
  margin-right: 10px;
}
.NotificationBlock .Message {
  margin-right: 0;
  margin-left: auto;
  width: content;
  margin-top: 5px;
}