.RuleList {
  max-width: 400px;
}
.RuleList-Link {
  font-size: 14px;
  color: var(--basis-colors-base-active);
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dashed;
  margin-top: 8px;
}