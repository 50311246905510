.ListPagePanelSoc {
  position: relative;
  background: var(--basis-colors-base-block);
  box-shadow: 0 0 4px 0 var(--basis-colors-shadows-default);
  border-left: 1px solid var(--basis-colors-borders-normal);
  width: 0;
  flex: 0 0 400px;
  z-index: 1;
  height: 100%;
  transition: flex-basis 0.24s;
  display: flex;
  justify-content: stretch;
}
.ListPagePanelSoc-ResizeMarker {
  position: fixed;
  margin-left: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  width: 20px;
  cursor: ew-resize;
}
.ListPagePanelSoc-Tabs {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
}
.ListPagePanelSoc-Tabs button {
  border: 0;
  padding: 16px;
}
.ListPagePanelSoc-Content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ListPagePanelSoc-Body {
  flex: 1 1 0;
  overflow: auto;
}
.ListPagePanelSoc .ListPagePanelSocHeader {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
  padding: 12px 16px 12px 8px;
  display: flex;
  align-items: center;
}
.ListPagePanelSoc .ListPagePanelSocHeader .Header {
  font-family: var(--basis-typography-fonts-accent);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 17px 0px;
  flex-grow: 1;
  word-break: break-word;
}
.ListPagePanelSoc .ListPagePanelSocHeader-Controls {
  flex-shrink: 0;
}
.ListPagePanelSoc_closed {
  flex-basis: 65px;
  overflow: hidden;
}
.ListPagePanelSoc_closed[style] {
  flex-basis: 65px !important;
}
@media only screen and (min-width: 1900px) {
  .ListPagePanelSoc_closed {
    transition: ease all 0.5s;
  }
}
.ListPagePanelSoc_closed .ListPagePanelSocHeader {
  height: 100%;
  width: 65px;
  padding: 18px 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ListPagePanelSoc_closed .ListPagePanelSocHeader .Header {
  order: 1;
  writing-mode: vertical-lr;
  width: 40px;
  line-height: 40px;
}
.ListPagePanelSoc_hide {
  flex-basis: 0;
}
.ListPagePanelSoc .PanelIcon {
  height: 40px;
}
.ListPagePanelSoc .PanelIcon svg {
  transition: transform 0.24s;
}
.ListPagePanelSoc .PanelIcon_active svg {
  transform: rotate(180deg);
}
.ListPagePanelSoc .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 4px 16px 4px 24px !important;
}
.ListPagePanelSoc .ant-collapse-header .Header {
  flex-grow: 1;
  word-break: break-word;
  margin: 12px 0;
}
.ListPagePanelSoc .ant-collapse-content-box {
  padding: 24px;
  padding-top: 16px;
}
.ListPagePanelSoc .ant-collapse-item:last-child {
  border: 0;
}
.ListPagePanelSoc .ant-collapse-item .noData {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}
.ListPagePanelSoc .Panel-RatingSection .Text {
  color: #999;
}