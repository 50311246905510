.RangeDatepicker-FadeInput .chakra-input__left-element,
.RangeDatepicker-FadeInput .chakra-input__right-element {
  display: none !important;
}
.RangeDatepicker-FadeInput .chakra-input,
.RangeDatepicker-FadeInput .chakra-input:focus {
  border: 0 !important;
}
.RangeDatepicker-FadeInput .chakra-input {
  height: 0px !important;
}
.RangeDatepicker-FadeInput .chakra-input:focus {
  box-shadow: unset !important;
}