.MitreWidget {
  margin-top: 16px;
}
.MitreWidget_isExpanded {
  margin: 0;
}
.MitreWidget-Tags {
  display: inline-block;
  margin-left: 16px;
}
.MitreWidget-Tag {
  display: inline-block;
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
}
.MitreWidget-Tag + .MitreWidget-Tag {
  margin-left: 4px;
}
.MitreWidget-Help {
  color: var(--basis-colors-text-default);
}
.MitreWidget-Help div {
  display: flex;
  align-items: center;
  margin-right: 13px;
}
.MitreWidget-Help div .Dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 4px;
}
.MitreWidget-Help div .Dot_critical {
  background-color: #e0281b;
}
.MitreWidget-Help div .Dot_high {
  background-color: var(--basis-colors-node-critical);
}
.MitreWidget-Help div .Dot_medium {
  background-color: var(--basis-colors-node-warning);
}
.MitreWidget-Help div .Dot_low {
  background-color: var(--basis-colors-node-attention);
}
.MitreWidget-Help div .Dot_none {
  background-color: var(--basis-colors-base-block);
}
.MitreWidget-Help div span {
  opacity: 0.7;
}
.MitreWidget table {
  display: table;
  overflow: auto;
  height: 700px;
  width: calc(100% - 8px);
  margin: 2px 2px;
}
.MitreWidget table thead {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
}
.MitreWidget table thead th {
  padding: 8px;
  vertical-align: top;
  color: var(--basis-colors-text-default);
  font-size: 12px;
  font-weight: normal;
}
.MitreWidget table tbody td {
  padding: 2px 2px;
  height: 100%;
}
.MitreWidget table tbody td .Technique {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  color: var(--basis-colors-text-secondary);
  background-color: var(--basis-colors-base-block);
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
}
.MitreWidget table tbody td .Technique:hover {
  box-shadow: 0 0 3px 0 var(--basis-colors-text-default);
}
.MitreWidget table tbody td .Technique_critical, .MitreWidget table tbody td .Technique_high, .MitreWidget table tbody td .Technique_medium, .MitreWidget table tbody td .Technique_low {
  color: var(--basis-colors-text-default);
}
.MitreWidget table tbody td .Technique_critical {
  background-color: #e0281b;
}
.MitreWidget table tbody td .Technique_high {
  background-color: var(--basis-colors-node-critical);
}
.MitreWidget table tbody td .Technique_medium {
  background-color: var(--basis-colors-node-warning);
}
.MitreWidget table tbody td .Technique_low {
  background-color: var(--basis-colors-node-attention);
}