.header-wrapper {
  text-align: center;
  margin-top: 20vh;
}
.header-wrapper .login-logo {
  display: inline-block;
}
.header-wrapper .login-title {
  color: var(--basis-colors-text-default);
  margin-top: -50px;
  font-family: var(--basis-typography-fonts-default);
}

.form-wrapper {
  text-align: center;
  justify-content: center;
  max-width: 400px;
}
.form-wrapper .login-form {
  min-width: 400px;
  max-width: 400px;
  display: inline-block;
}
.form-wrapper .login-form .submit-button-wrapper > button {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
}
.form-wrapper .login-form .ant-form-explain {
  padding-top: 5px;
}
.form-wrapper .login-form input,
.form-wrapper .login-form button {
  height: 40px;
}