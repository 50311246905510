.InstructionsPanel {
  background: var(--basis-colors-base-block);
  box-shadow: 0 0 4px 0 var(--basis-colors-shadows-default);
  border-left: 1px solid var(--basis-colors-borders-normal);
  flex: 0 0 400px;
  overflow: auto;
  z-index: 1;
  transition: flex-basis 0.24s;
}
.InstructionsPanel .InstructionsPanelHeader {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
  padding: 12px 16px 12px 8px;
  display: flex;
  align-items: center;
}
.InstructionsPanel .InstructionsPanelHeader .Header {
  flex-grow: 1;
  word-break: break-word;
}
.InstructionsPanel .InstructionsPanelHeader-Controls {
  flex-shrink: 0;
}
.InstructionsPanel_closed {
  flex-basis: 65px;
  overflow: hidden;
}
@media only screen and (min-width: 1900px) {
  .InstructionsPanel_closed {
    transition: ease all 0.5s;
    margin-left: -60px;
  }
}
.InstructionsPanel_closed .InstructionsPanelHeader {
  height: 100%;
  width: 65px;
  padding: 18px 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.InstructionsPanel_closed .InstructionsPanelHeader .Header {
  order: 1;
  writing-mode: vertical-lr;
  width: 40px;
  line-height: 40px;
}
.InstructionsPanel_hide {
  flex-basis: 0;
}
.InstructionsPanel .PanelIcon {
  transition: transform 0.24s;
  height: 40px;
}
.InstructionsPanel .PanelIcon_active {
  transform: rotate(180deg);
}
.InstructionsPanel .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 4px 16px 4px 24px !important;
}
.InstructionsPanel .ant-collapse-header .Header {
  flex-grow: 1;
  word-break: break-word;
}
.InstructionsPanel .ant-collapse-content-box {
  padding: 24px;
  padding-top: 16px;
}
.InstructionsPanel .ant-collapse-item:last-child {
  border: 0;
}
.InstructionsPanel .ant-collapse-item .noData {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}
.InstructionsPanel .Panel-RatingSection .Text {
  color: #999;
}