.Files {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.Files .Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.Files .Header-Title {
  font-weight: bold;
}
.Files .NoPreview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.Files .NoPreview button {
  margin-top: 10px;
}
.Files .Preview {
  width: 100%;
  flex-grow: 1;
  position: relative;
  overflow: auto;
}
.Files .Preview .Overlay {
  z-index: unset;
}
.Files .Preview .IFrameParent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.Files .Preview .IFrameParent iframe {
  border-width: 0;
  width: 100%;
  height: 100%;
}
.Files .Preview .ImagePreview {
  text-align: center;
}
.Files .Preview .ImagePreview img.can_zoom {
  max-width: 100%;
  cursor: zoom-in;
}
.Files .Preview .ImagePreview img.can_zoom_out {
  min-width: 100%;
  cursor: zoom-out;
}