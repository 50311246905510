.ReportsPanel {
  background: var(--basis-colors-base-block);
  box-shadow: 0 0 4px 0 var(--basis-colors-shadows-default);
  border-right: 1px solid var(--basis-colors-borders-normal);
  flex: 0 0 200px;
  overflow: auto;
  z-index: 4;
  transition: flex-basis 0.24s;
}
.ReportsPanel .ReportsPanelHeader {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
  padding: 12px 16px 12px 8px;
  display: flex;
  align-items: center;
}
.ReportsPanel .ReportsPanelHeader .Header {
  flex-grow: 1;
  word-break: break-word;
}
.ReportsPanel .ReportsPanelHeader-Controls {
  flex-shrink: 0;
}
.ReportsPanel_closed {
  flex-basis: 60px;
  overflow: hidden;
}
@media only screen and (min-width: 1900px) {
  .ReportsPanel_closed {
    transition: ease all 0.25s;
  }
}
.ReportsPanel_closed .TabItem-Text {
  display: none !important;
}
.ReportsPanel .PanelIcon {
  transition: transform 0.24s;
  height: 40px;
}
.ReportsPanel .PanelIcon_active {
  transform: rotate(180deg);
}
.ReportsPanel .TabItems-Container {
  display: block;
  flex-direction: column;
}
.ReportsPanel .TabItems-Container .TabItem {
  display: flex;
  padding: 12px 16px !important;
  text-decoration: none;
  font-family: var(--basis-typography-fonts-default);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4d4d4d;
  transition: color 0.1s ease;
  cursor: pointer;
}
.ReportsPanel .TabItems-Container .TabItem:hover {
  padding: 12px 12px !important;
  border-left: 4px solid rgba(0, 0, 0, 0.1);
}
.ReportsPanel .TabItems-Container .TabItem_active {
  padding: 12px 12px !important;
  border-left: 4px solid #1896cc !important;
}
.ReportsPanel .TabItems-Container .TabItem .Row {
  display: flex;
}
.ReportsPanel .TabItems-Container .TabItem .TabItem-Text {
  display: inline;
}