.IncidentsTab {
  position: relative;
  height: calc(100vh - 94px);
}
.IncidentsTab-Cards {
  height: 100%;
  overflow-y: auto;
  margin: -16px 0;
  padding: 16px 0;
}
.IncidentsTab-NoData {
  --text-color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}