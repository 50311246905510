.ProtectedSystemCard {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin-left: 250px;
}
.ProtectedSystemCard section + section {
  margin-top: 16px;
}
.ProtectedSystemCard .divider {
  margin-top: 32px;
}