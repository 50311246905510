.Anchors {
  position: absolute;
  width: 250px;
  padding-right: 16px;
}
.Anchors a .Node {
  position: relative;
  box-shadow: none;
  transition: box-shadow 0.2s linear;
  margin-right: 4px;
}
.Anchors a.current .Node {
  box-shadow: 4px 0 0 0 var(--basis-colors-base-active);
}