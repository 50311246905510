.NCCCI-TimerLabel {
  display: inline-block;
  padding: 0 16px;
}
.NCCCI-TimerLabel span {
  color: #999999;
  margin-right: 8px;
}
.NCCCI-ErrorLabel {
  display: inline-block;
  padding: 0 16px;
  color: #e04a3f;
}