.DescriptionContainer {
  overflow: hidden;
}
.DescriptionContainer_collapsed {
  box-sizing: border-box;
  padding-bottom: 0.1em;
}

.ReadMoreBtn {
  margin-top: 10px;
}
.ReadMoreBtn span {
  cursor: pointer;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.65);
}
.ReadMoreBtn span:hover {
  color: black;
  border-bottom-color: black;
}
.ReadMoreBtn span:active {
  border-bottom-style: solid;
}