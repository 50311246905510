.VersionSection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.VersionSection-CommentSection {
  font-size: 12px;
  padding-top: 32px;
}
.VersionSection-CommentHeader {
  color: #999999;
  padding-bottom: 4px;
}
.VersionSection-noData {
  text-align: center;
}