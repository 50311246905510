.RuleHeader .login-logo {
  display: inline-block;
  width: 50px;
}
.RuleHeader .Header {
  color: #c8d4e1;
}

.Rule {
  height: calc(100vh - 56px);
  background-color: #17212d;
}
.Rule-Block {
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: #131c26;
  position: relative;
  z-index: 2;
}
.Rule-Block .Text {
  color: #6983a2;
}
.Rule-Block .List-Value,
.Rule-Block .Subheader {
  color: #c8d4e1;
}
.Rule-Block::before {
  background: rgba(255, 255, 255, 0);
  background-size: contain;
  content: "";
  opacity: 0.1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-position-x: center;
  background-position-y: center;
  z-index: -1;
}
.Rule-BlockDivider {
  margin-top: 40px;
  background: rgba(255, 255, 255, 0.1);
}
.Rule-BlockHeader {
  margin-bottom: 24px;
}
.Rule-BlockHeader .Header {
  color: #c8d4e1;
}

.TechniqueInfo {
  width: 100%;
}
.TechniqueInfo:not(:first-child) {
  padding-top: 12px;
}
.TechniqueInfo-Name {
  margin-bottom: 8px;
}
.TechniqueInfo-Link {
  margin-bottom: 8px;
}
.TechniqueInfo-Description {
  color: #6983a2;
}
.TechniqueInfo-Description .Markdown {
  font-family: var(--basis-typography-fonts-default);
  padding: 0;
  background: rgba(255, 255, 255, 0);
  color: #c8d4e1;
}
.TechniqueInfo-Description .Markdown h1,
.TechniqueInfo-Description .Markdown h2,
.TechniqueInfo-Description .Markdown h3,
.TechniqueInfo-Description .Markdown h4,
.TechniqueInfo-Description .Markdown h5 {
  color: #c8d4e1;
}
.TechniqueInfo-Description .Markdown table {
  color: #c8d4e1;
}
.TechniqueInfo-Description .Markdown table thead tr {
  background-color: #17212d;
}
.TechniqueInfo-Description .Markdown table thead th {
  font-weight: bold;
}
.TechniqueInfo-Description .Markdown table tr {
  background-color: #131c26;
}
.TechniqueInfo-Description .Markdown table tr:nth-child(2n) {
  background-color: #17212d;
}
.TechniqueInfo-Description .ReadMoreBtn span {
  border-bottom: 1px dashed #6983a2;
}
.TechniqueInfo-Description .ReadMoreBtn :hover {
  color: #7f98b6;
}

.Rule-BlockContent {
  padding: 8px 160px 72px 160px;
}
.Rule-BlockContent .List-Value {
  width: 100%;
}