.change-password-form-wrapper {
  text-align: center;
  margin-top: 40px;
}
.change-password-form-wrapper form {
  padding-top: 10px;
  min-width: 400px;
  max-width: 400px;
  display: inline-block;
  text-align: left;
}
.change-password-form-wrapper form .Header {
  padding-bottom: 1em;
}
.change-password-form-wrapper form .Button {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}
.change-password-form-wrapper .Tooltip {
  color: #fff;
}