.RuleCard {
  position: relative;
  display: flex;
  background-color: var(--components-card-colors-background-normal);
  height: auto;
  max-width: 1408px;
  min-width: 608px;
  border: solid 1px var(--components-card-colors-border-normal);
  padding: 16px;
  border-radius: 4px;
  margin: 5px auto 20px;
  cursor: pointer;
}
.RuleCard:hover {
  box-shadow: 0 0 4px 0 var(--components-card-colors-border-selected);
}
.RuleCard-Selected {
  background-color: var(--components-card-colors-background-selected);
  border-color: var(--components-card-colors-border-selected);
}
.RuleCard .Icons {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  gap: 7px;
}
.RuleCard .Main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 552px;
}
.RuleCard .Header {
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
}
.RuleCard .HeadingM {
  font-family: var(--basis-typography-fonts-default);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--basis-colors-link-normal);
}
.RuleCard .HeadingM-Link {
  max-width: 85%;
}
.RuleCard .Caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 16px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--components-text-colors-secondary);
}
.RuleCard .Separator {
  min-width: 2px;
  height: 14px;
  margin: 5px 8px;
  background-color: var(--components-badge-colors-transparent-border-default);
}
.RuleCard .Badge {
  display: flex;
  height: 24px;
  font-family: var(--basis-typography-fonts-default);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  padding: 4px 8px;
  margin: 4px 4px 0 0;
  cursor: pointer;
  max-width: 191px;
  background-color: var(--components-card-colors-background-normal);
}
.RuleCard .Badge span {
  margin: 0 4px;
}
.RuleCard .Badge-InnerText {
  max-width: 400px;
  display: block;
}
.RuleCard .Badge-Text {
  display: block;
  padding: 0;
  margin: 0;
  height: 14px;
}
.RuleCard .Badge-Link {
  min-width: 14px;
  margin-left: 2px;
}
.RuleCard .Badge-Link-Color {
  color: var(--basis-colors-link-normal);
}
.RuleCard .Badge-Border {
  border: 1px solid var(--components-badge-colors-transparent-border-default);
  border-radius: 3px;
}
.RuleCard .Badge-Border-Link {
  border: 1px solid var(--basis-colors-link-normal);
  border-radius: 3px;
}
.RuleCard .Text {
  font-family: var(--basis-typography-fonts-default);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin: 2px 0 0 4px;
}
.RuleCard .Levels {
  width: 244px;
  max-width: 244px;
  max-height: 128px;
}
.RuleCard .Levels-Short {
  justify-content: unset;
  width: 106px;
  flex-direction: column;
}
.RuleCard .Levels .Indicator {
  margin: 4px 0 0;
}
.RuleCard .Levels,
.RuleCard .Availables,
.RuleCard .Categories,
.RuleCard .Mitre,
.RuleCard .Trust,
.RuleCard .Importance {
  display: flex;
  margin-right: 40px;
}
.RuleCard .Availables,
.RuleCard .Categories,
.RuleCard .Mitre,
.RuleCard .Trust,
.RuleCard .Importance {
  flex-direction: column;
}
.RuleCard .Trust {
  width: 100px;
  min-width: 100px;
}
.RuleCard .Trust-Short {
  margin-bottom: 16px;
}
.RuleCard .Importance {
  width: 106px;
  min-width: 106px;
}
.RuleCard .Availables {
  width: 142px;
}
.RuleCard .Availables-Small {
  display: none;
}
.RuleCard .Availables-Items {
  flex-wrap: wrap;
}
.RuleCard .Categories {
  flex-grow: 2;
  width: 159px;
  min-width: 159px;
  max-height: 100%;
}
.RuleCard .Mitre {
  width: 191px;
  flex-grow: 3;
  min-width: 191px;
  max-height: 100%;
}
.RuleCard .Tag-Content {
  display: flex;
  flex-wrap: wrap;
}