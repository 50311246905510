.DashboardHeader {
  width: 100%;
  background: var(--basis-colors-base-block);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-shrink: 0;
  overflow: unset;
}
.DashboardHeader-Content {
  display: flex;
  align-items: flex-start;
}
.DashboardHeader-Controls {
  display: flex;
  margin-top: -8px;
  min-width: 280px;
  flex-direction: row-reverse;
}
.DashboardHeader-Controls > * {
  margin-top: 8px;
}

.dashboard-edit button {
  margin-right: 5px;
}

.DashboardContainer {
  align-self: stretch;
}
.DashboardContainer .DashboardContent {
  max-width: 1730px;
}

@media not all and (min-resolution: 0.001dpcm) {
  .DashboardContainer::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
}