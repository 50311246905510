@primary-color-global: #209ACE; @primary-hover-color-global: #1589BB; @disabled-primary-color-global: #A8D9EE; @select-color-global: #E4F3F9; @selected-hover-color-global: #D7EDF6; @card-shadow-box-global: rgba(77, 77, 77, 0.2); @input-shadow-box-global: rgba(24, 150, 204, 0.2); @header-color-global: #2D3C53; @fonts-global: [object Object],[object Object],[object Object]; @font-family-global: Roboto, Helvetica, Arial, sans-serif; @font-family-mono-global: Roboto Mono, Helvetica, Arial, sans-serif; @font-family-accent-global: Ubuntu, Helvetica, Arial, sans-serif; @login-logo-global: undefined; @header-logo-global: undefined;
@import '~antd/dist/antd.less';
@card-radius: 4px;
@border-color-split: #e6e6e6;
@font-family: var(--basis-typography-fonts-default);
@pagination-font-family: var(--basis-typography-fonts-default);
@layout-header-height: 48px;
@layout-header-background: @header-color-global;
@layout-body-background: #f9f9f9;

@menu-dark-item-active-bg: #000000;
@menu-dark-color: fade(#ffffff, 80%);
@menu-dark-highlight-color: #fff;
@menu-highlight-color: #fff;

@primary-color: @primary-color-global;
@info-color: @primary-color-global;

@card-head-padding: 19px;
@card-padding-wider: 23px;

@collapse-header-bg: #fff;
@zindex-notification: 9999;
