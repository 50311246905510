.instruction-card {
  color: var(--basis-colors-text-default);
}
.instruction-card section + section {
  margin-top: 16px;
}
.instruction-card .mainInfo {
  display: flex;
}
.instruction-card .mainInfo > div:first-child {
  flex-grow: 1;
}
.instruction-card .mainInfo > div:last-child {
  min-width: 200px;
}