.DocumentationContainer {
  justify-content: flex-start;
  width: 100vw;
}
.DocumentationContainer .RulesIcon {
  margin-right: 6px;
  margin-left: 1px;
}
.DocumentationContainer .TabItems-Container {
  display: block;
  flex-direction: column;
}
.DocumentationContainer .TabItems-Container .TabItem {
  display: flex;
  padding: 16px 14px;
  text-decoration: none;
  font-family: var(--basis-typography-fonts-default);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4d4d4d;
  transition: color 0.1s ease;
  cursor: pointer;
}
.DocumentationContainer .TabItems-Container .TabItem_active {
  border-left: 4px solid var(--basis-colors-base-active) !important;
}
.DocumentationContainer .DocumentationContent {
  padding: 0;
  max-width: unset;
  max-height: unset;
  display: flex;
  flex-direction: column;
}

.InstructionsCard {
  background-color: var(--components-card-colors-background-normal);
  border-radius: 4px;
  border: 1px solid var(--components-card-colors-border-normal);
  padding: 16px 24px 24px 24px;
  cursor: pointer;
  width: 100%;
  max-width: 1344px;
  margin: auto;
}
.InstructionsCard:hover {
  box-shadow: 0 0 4px 0 var(--components-card-colors-border-selected);
}
.InstructionsCard_selected {
  background-color: var(--components-card-colors-background-selected);
  border-color: var(--components-card-colors-border-selected);
}
.InstructionsCard:not(:first-child) {
  margin-top: 16px;
}
.InstructionsCard-Header {
  display: flex;
  flex-direction: column;
}
.InstructionsCard-Header .Header {
  flex-grow: 1;
}
.InstructionsCard-Date {
  color: #999;
  display: flex;
  margin-top: 4px;
}
.InstructionsCard-Date > div {
  display: flex;
  align-items: center;
  line-height: 1;
}
.InstructionsCard-Date > div i {
  margin-right: 3px;
}
.InstructionsCard-Date div + div {
  margin-left: 10px;
}
.InstructionsCard-Body {
  display: flex;
  margin-top: 16px;
}
.InstructionsCard-FirstList {
  flex-basis: 360px;
  flex-shrink: 0;
}
.InstructionsCard-SecondList {
  flex-grow: 1;
  margin-left: 30px;
}