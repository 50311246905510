.Attachments {
  display: flex;
  flex-direction: column;
}

.attachments-file-name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachments-file-size {
  padding: 0 8px;
  white-space: nowrap;
}