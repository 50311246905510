.ReportsContainer {
  justify-content: flex-start;
}
.ReportsContainer .TabItems-Container {
  display: block;
  flex-direction: column;
}
.ReportsContainer .TabItems-Container .TabItem {
  display: flex;
  padding: 16px 14px;
  text-decoration: none;
  font-family: var(--basis-typography-fonts-default);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4d4d4d;
  transition: color 0.1s ease;
  cursor: pointer;
}
.ReportsContainer .TabItems-Container .TabItem_active {
  border-left: 4px solid var(--basis-colors-base-active) !important;
}
.ReportsContainer .ReportsContent {
  padding: 0;
  max-width: unset;
  max-height: unset;
  display: flex;
  flex-direction: column;
}