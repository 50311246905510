.ListPage.RuleCards .ListPageContainer .ListPageBody-Cards {
  padding: 0 16px 16px;
}

.ListPage {
  justify-content: flex-start;
}
.ListPage .ListPageHeader {
  width: 100%;
  background-color: var(--basis-colors-base-block) !important;
  padding: 24px 16px;
  box-shadow: 0 0 4px 0 var(--basis-colors-shadows-default);
  z-index: 0 !important;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
}
.ListPage .ListPageHeader-ControlsRow {
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-width: 1344px;
  margin: auto;
}
.ListPage .ListPageHeader-ControlsRow > :not(:first-child) {
  margin-left: 8px;
}
.ListPage .ListPageHeader-Search {
  display: flex;
  flex-grow: 1;
}
.ListPage .ListPageHeader-Search button {
  margin-left: 8px;
}
.ListPage .ListPageHeader-FiltersRow {
  width: 100%;
  max-width: 1344px;
  margin: auto;
}
.ListPage .ListPageHeader-FiltersRow .FilterPanel {
  margin-top: 8px;
}
.ListPage .ListPageContainer {
  padding: 0;
  max-width: unset;
  max-height: unset;
  display: flex;
  flex-direction: column;
}
.ListPage .ListPageContainer .ListPageBody {
  display: flex;
  position: relative;
  flex-grow: 1;
  min-height: 0;
}
.ListPage .ListPageContainer .ListPageBody-Cards {
  flex-grow: 1;
  min-height: 0;
  padding: 16px;
  overflow: auto;
  max-width: 100%;
  width: 0;
}
@-moz-document url-prefix() {
  .ListPage .ListPageContainer .ListPageBody-Cards:after {
    content: "";
    display: block;
    padding-bottom: 16px;
  }
}
.ListPage .ListPageContainer .ListPageBody_fetching {
  overflow: hidden;
}
.ListPage .ListPageContainer .ListPageBody_fetching .Overlay {
  background-clip: content-box;
  z-index: 1;
}
.ListPage .ListPageContainer .ListPageBody-NoData {
  --text-color: #999;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow: auto;
  padding: 96px 0 16px;
}
.ListPage .ant-collapse-header {
  padding-left: 16px !important;
}
.ListPage .ant-collapse-content-box {
  padding-right: 0;
  padding-left: 0;
}
.ListPage .ListPagePanel {
  background-color: var(--basis-colors-base-block);
  box-shadow: 0 0 4px 0 var(--basis-colors-shadows-default);
  border-left: 1px solid var(--basis-colors-borders-normal);
}
.ListPage .ListPagePanelHeader {
  border-bottom: 1px solid var(--basis-colors-borders-normal);
}