.pull-right {
  float: right;
}

.ant-rate-disabled {
  color: var(--basis-colors-base-active);
}

.ant-rate-disabled .ant-rate-star > div:hover {
  transform: scale(1);
}

.FlexWrap {
  flex-wrap: wrap;
}

.RowWithIcon {
  display: flex;
  align-items: center;
}
.RowWithIcon .Icon {
  margin-right: 8px;
  font-size: 20px;
}
.RowWithIcon .Icon.bz-icon-undefined {
  margin-right: 0;
}

.LinkBadge {
  border-color: var(--basis-colors-base-active) !important;
}
.LinkBadge span,
.LinkBadge svg[style] {
  color: var(--basis-colors-base-active) !important;
}

.BreadCrumb-Controls {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}

.Modal-CloseIcon {
  height: 100%;
  display: flex;
  text-align: right;
  justify-content: center;
  flex-direction: column;
  padding-right: 24px;
}