.RightPanel {
  height: 100%;
}
.RightPanel-Content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.RightPanel .CommentsSearch {
  padding: 8px 16px;
  width: 100%;
}
.RightPanel .CommentList {
  flex: 0 1 100%;
  flex-direction: 0;
  overflow: scroll;
  padding-bottom: 15px;
  margin-bottom: 5px;
  position: relative;
}
.RightPanel .CommentList-Date {
  padding: 10px;
  text-transform: capitalize;
  text-align: center;
}
.RightPanel .CommentShadow {
  width: 100%;
  height: 20px;
  box-shadow: 0px -10px 10px #fff;
  z-index: 1000;
}
.RightPanel .AddComment {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
}
.RightPanel .AddComment-InputWrapper {
  flex: 1;
}