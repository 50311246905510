.Controls {
  position: absolute;
  right: 10px;
}
.Controls-Icons {
  opacity: 0.7;
}

.SheduleCard {
  background-color: var(--components-card-colors-background-normal);
  border-radius: 4px;
  border: 1px solid var(--components-card-colors-border-normal);
  padding: 16px 24px 24px 24px;
  cursor: pointer;
  width: 100%;
  min-width: 1200px;
  max-width: 1344px;
  margin: auto;
}
.SheduleCard:hover {
  box-shadow: 0 0 4px 0 var(--components-card-colors-border-selected);
}
.SheduleCard_selected {
  background-color: var(--components-card-colors-background-selected);
  border-color: var(--components-card-colors-border-selected);
}
.SheduleCard:not(:first-child) {
  margin-top: 16px;
}
.SheduleCard-Header {
  display: flex;
  flex-direction: column;
}
.SheduleCard-Header .Header {
  flex-grow: 1;
  color: var(--basis-colors-base-active);
}
.SheduleCard-Date {
  color: var(--basis-colors-text-default);
  display: flex;
  margin-top: 4px;
}
.SheduleCard-Date > div {
  display: flex;
  align-items: center;
  line-height: 1;
}
.SheduleCard-Date > div i {
  margin-right: 3px;
}
.SheduleCard-Date div + div {
  margin-left: 10px;
}
.SheduleCard-Body {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}
.SheduleCard-FirstList {
  flex-basis: 360px;
  flex-shrink: 0;
}
.SheduleCard-FirstList .PeriodField {
  display: flex;
  align-items: center;
}
.SheduleCard-FirstList .PeriodField i {
  margin-right: 8px;
  color: var(--basis-colors-icon-normal);
}
.SheduleCard-SecondList {
  width: 300px;
  margin-left: 30px;
}