.GeneralBlock {
  border-left: 1px solid var(--basis-colors-borders-normal);
  margin: 2px 0px;
  padding: 0px 20px;
  margin-bottom: -5px;
  align-self: flex-end;
}
.GeneralBlock-Value {
  color: var(--basis-colors-base-critical);
  font-size: 20px;
  font-weight: 500;
}
.GeneralBlock-Title {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--basis-colors-text-secondary);
}