g text {
  fill: var(--basis-colors-text-secondary);
}
g polyline {
  stroke: var(--basis-colors-borders-normal);
  stroke-opacity: 1;
}
g[stroke-opacity="0.15"] {
  stroke: var(--basis-colors-borders-normal);
  stroke-opacity: 1;
}