.EventHeader-Logo {
  display: inline-block;
  width: 50px;
}
.EventHeader .Header {
  color: #c8d4e1;
}

.Event {
  justify-content: flex-start;
  height: calc(100vh - 56px);
  background-color: #17212d;
  color: #6983a2;
}
.Event .Layout-Content {
  padding: 20px 100px 20px 20px;
  max-width: unset;
  max-height: unset;
  overflow-y: auto;
}
.Event-Block {
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: #131c26;
  position: relative;
  z-index: 2;
}
.Event-Block .Text {
  color: #6983a2;
}
.Event-Block .List-Value,
.Event-Block .Subheader {
  color: #c8d4e1;
}
.Event-BlockHeader {
  margin-bottom: 24px;
}
.Event-BlockHeader .Header {
  color: #c8d4e1;
  text-transform: capitalize;
}
.Event .Markdown {
  font-family: var(--basis-typography-fonts-default);
  padding: 0;
  z-index: 1;
  background-color: transparent;
  color: #c8d4e1;
}
.Event .Markdown::before {
  content: "";
  opacity: 0.1;
  z-index: -1;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0);
  background-size: 100% auto;
  background-position: center;
  position: fixed;
  --calc-height: calc(
    100vh - 48px - 56px - 20px -
      20px
  );
  --calc-width: calc(
    100vw - 20px - 100px -
      var(--panel-width, 400px)
  );
  --calc-left: calc(
    20px + var(--panel-width, 400px)
  );
  --calc-top: calc(
    48px + 56px + 20px
  );
  height: min(var(--content-height, var(--calc-height)), var(--calc-height));
  width: var(--content-width, var(--calc-width));
  left: var(--content-left, var(--calc-left));
  top: var(--calc-top);
}
.Event .Markdown h1,
.Event .Markdown h2,
.Event .Markdown h3,
.Event .Markdown h4,
.Event .Markdown h5 {
  color: #c8d4e1;
}
.Event .Markdown p {
  margin: 0 !important;
}
.Event .Markdown ul {
  margin: 0 0 0.5rem 0 !important;
}
.Event .Markdown table {
  color: #c8d4e1;
}
.Event .Markdown table thead tr {
  background-color: rgba(40, 56, 77, 0.2);
}
.Event .Markdown table thead th,
.Event .Markdown table thead .confluenceTh {
  font-weight: bold;
}
.Event .Markdown table tr {
  background-color: transparent;
}
.Event .Markdown table tr:nth-child(2n) {
  background-color: rgba(40, 56, 77, 0.2);
}
.Event-BlockContent {
  padding: 8px 80px 72px 80px;
}
.Event-noData .Header {
  color: #c8d4e1;
}